import SkillId from '../../../../types/business/skill/SkillId';
import TagId, { DEFAULT_TAG_ID } from '../../../../types/business/tag/TagId';
import TagGroup, {
  DEFAULT_TAG_GROUP,
} from '../../../../types/business/tag/group/TagGroup';
import Experience from '../../../../types/business/experience/Experience';
import ExperienceGroup from '../../../../types/business/experience/group/ExperienceGroup';
import { DEFAULT_EXPERIENCE_GROUP_ID } from '../../../../types/business/experience/group/ExperienceGroupId';

const groupExperiencesByTags = (
  experiences: Experience[],
): Map<TagId, Experience[]> => {
  const experiencesByTags = new Map<TagId, Experience[]>();

  experiences.forEach((experience) => {
    if (experience.tags && experience.tags.length > 0) {
      experience.tags.forEach((tag) => {
        if (experiencesByTags.has(tag.id)) {
          experiencesByTags.get(tag.id)?.push(experience);
        } else {
          experiencesByTags.set(tag.id, [experience]);
        }
      });
    } else if (experiencesByTags.has(DEFAULT_TAG_ID)) {
      experiencesByTags.get(DEFAULT_TAG_ID)?.push(experience);
    } else {
      experiencesByTags.set(DEFAULT_TAG_ID, [experience]);
    }
  });

  return experiencesByTags;
};

const initializeExperienceGroups = (
  tagGroups: TagGroup[],
): ExperienceGroup[] => {
  const experienceGroups: ExperienceGroup[] = [];
  tagGroups.forEach((tagGroup, index) => {
    experienceGroups.push({
      id: index,
      tagGroup,
      experiences: [],
    });
  });

  return experienceGroups;
};

const retrieveExperiencesForProvidedTagsWithoutDuplicates = (
  tagIds: TagId[],
  experiencesTagMap: Map<TagId, Experience[]>,
  usedSkillIds: Set<SkillId>,
): Experience[] => {
  const skillIdToExperience: Map<SkillId, Experience> = new Map<
    SkillId,
    Experience
  >();

  tagIds.forEach((tagId) => {
    experiencesTagMap.get(tagId)?.forEach((experience) => {
      skillIdToExperience.set(experience.id, experience);
      usedSkillIds.add(experience.id);
    });
  });

  return Array.from(skillIdToExperience.values());
};

const createDefaultExperienceGroup = (
  usedSkillIds: Set<SkillId>,
  experiences: Experience[],
): ExperienceGroup => ({
  id: DEFAULT_EXPERIENCE_GROUP_ID,
  tagGroup: DEFAULT_TAG_GROUP,
  experiences: experiences.filter(
    (experience) => !usedSkillIds.has(experience.id),
  ),
});

const groupSkillsExperienceByTagGroupsWithDuplicates = (
  tagGroups: TagGroup[],
  experiences: Experience[],
): ExperienceGroup[] => {
  const experiencesTagMap: Map<TagId, Experience[]> =
    groupExperiencesByTags(experiences);
  const experienceGroups: ExperienceGroup[] =
    initializeExperienceGroups(tagGroups);

  const usedSkillIds = new Set<SkillId>();
  experienceGroups.forEach((experienceGroup: ExperienceGroup) => {
    experienceGroup.experiences =
      retrieveExperiencesForProvidedTagsWithoutDuplicates(
        experienceGroup.tagGroup.tags.map((tag): TagId => tag.id),
        experiencesTagMap,
        usedSkillIds,
      );
  });
  experienceGroups.push(
    createDefaultExperienceGroup(usedSkillIds, experiences),
  );

  return experienceGroups;
};

export default groupSkillsExperienceByTagGroupsWithDuplicates;
