export const BUTTON_CONTACT_TEXT_MAPPING = 'buttons.contact.text';
export const BUTTON_FILTER_TOOLTIP_MAPPING = 'buttons.filter.tooltip';
export const BUTTON_EXPERIENCE_TOOLTIP_MAPPING = 'buttons.experience.tooltip';
export const CARD_ACTIVITY_TYPE_PERSONAL_MAPPING =
  'cards.activity.type.personal';
export const CARD_ACTIVITY_TYPE_COMMERCIAL_MAPPING =
  'cards.activity.type.commercial';
export const CARD_ACTIVITY_TYPE_STARTUP_MAPPING = 'cards.activity.type.startup';
export const CARD_ACTIVITY_TYPE_INTERNSHIP_MAPPING =
  'cards.activity.type.internship';
export const CARD_ACTIVITY_TYPE_EDUCATIONAL_MAPPING =
  'cards.activity.type.education';
export const CHART_SKILLS_DEFAULT_TITLE_MAPPING = 'charts.skills.default.title';
export const CHIP_FILTER_PLACEHOLDER_TEXT_MAPPING =
  'chips.filter.placeholder.text';
export const CHIP_FILTER_PREFIX_ACTIVITY_TYPE_MAPPING =
  'chips.filter.prefix.activityType';
export const CHIP_FILTER_PREFIX_ACTIVITY_POSITION_MAPPING =
  'chips.filter.prefix.activityPosition';
export const CHIP_FILTER_PREFIX_ACTIVITY_PERIOD_MAPPING =
  'chips.filter.prefix.activityPeriod';
export const CHIP_FILTER_PREFIX_ACTIVITY_PERIOD_LAST_YEAR_MAPPING =
  'chips.filter.activityPeriod.lastYear';
export const CHIP_FILTER_PREFIX_ACTIVITY_PERIOD_LAST_FIVE_YEARS_MAPPING =
  'chips.filter.activityPeriod.lastFiveYears';
export const CHIP_FILTER_PREFIX_ACTIVITY_PERIOD_UNKNOWN_MAPPING =
  'chips.filter.activityPeriod.unknown';
export const CHIP_FILTER_PREFIX_ACTIVITY_PERIOD_CUSTOM_FROM_MAPPING =
  'chips.filter.activityPeriod.custom.from';
export const CHIP_FILTER_PREFIX_ACTIVITY_PERIOD_CUSTOM_TO_MAPPING =
  'chips.filter.activityPeriod.custom.to';
export const CHIP_FILTER_PREFIX_ACTIVITY_PERIOD_CUSTOM_UNTIL_MAPPING =
  'chips.filter.activityPeriod.custom.until';
export const DIALOG_CONTACT_TITLE_MAPPING = 'dialogs.contact.title';
export const DIALOG_CONTACT_CLOSE_MAPPING = 'dialogs.contact.close';
export const DIALOG_CONTACT_REGULAR_ALERT_TEXT_MAPPING =
  'dialogs.contact.alerts.regular.text';
export const DIALOG_CONTACT_WARNING_ALERT_TITLE_MAPPING =
  'dialogs.contact.alerts.warning.title';
export const DIALOG_CONTACT_WARNING_ALERT_TEXT_MAPPING =
  'dialogs.contact.alerts.warning.text';
export const DIALOG_FILTER_TITLE_MAPPING = 'dialogs.filter.title';
export const DIALOG_FILTER_CLOSE_MAPPING = 'dialogs.filter.close';
export const DIALOG_FILTER_APPLY_MAPPING = 'dialogs.filter.apply';
export const DIALOG_FILTER_RESET_ALL_MAPPING = 'dialogs.filter.resetAll';
export const DIALOG_FILTER_ACCORDION_RESET_MAPPING =
  'dialogs.filter.accordion.reset';
export const DIALOG_FILTER_ACCORDION_ACTIVITY_TYPE_TITLE_MAPPING =
  'dialogs.filter.accordion.activityType.title';
export const DIALOG_FILTER_ACCORDION_ACTIVITY_POSITION_TITLE_MAPPING =
  'dialogs.filter.accordion.activityPosition.title';
export const DIALOG_FILTER_ACCORDION_POSITION_SPECIALIZATION_TITLE_MAPPING =
  'dialogs.filter.accordion.positionSpecialization.title';
export const DIALOG_FILTER_ACCORDION_ACTIVITY_PERIOD_TITLE_MAPPING =
  'dialogs.filter.accordion.activityPeriod.title';
export const DIALOG_FILTER_ACCORDION_ACTIVITY_PERIOD_NONE_MAPPING =
  'dialogs.filter.accordion.activityPeriod.none';
export const DIALOG_FILTER_ACCORDION_ACTIVITY_PERIOD_LAST_YEAR_MAPPING =
  'dialogs.filter.accordion.activityPeriod.lastYear';
export const DIALOG_FILTER_ACCORDION_ACTIVITY_PERIOD_LAST_FIVE_YEARS_MAPPING =
  'dialogs.filter.accordion.activityPeriod.lastFiveYears';
export const DIALOG_FILTER_ACCORDION_ACTIVITY_PERIOD_CUSTOM_TITLE_MAPPING =
  'dialogs.filter.accordion.activityPeriod.custom.title';
export const DIALOG_FILTER_ACCORDION_ACTIVITY_PERIOD_CUSTOM_FROM_MAPPING =
  'dialogs.filter.accordion.activityPeriod.custom.from';
export const DIALOG_FILTER_ACCORDION_ACTIVITY_PERIOD_CUSTOM_TO_MAPPING =
  'dialogs.filter.accordion.activityPeriod.custom.to';
export const TEXT_TRUNCATABLE_READ_MORE_MAPPING = 'text.truncatable.readMore';
export const TEXT_COPYABLE_TOOLTIP_SUCCESS_MAPPING =
  'text.copyable.tooltip.success';
export const TEXT_COPYABLE_TOOLTIP_FAILURE_MAPPING =
  'text.copyable.tooltip.failure';
export const TOGGLE_THEME_TOOLTIP_MAPPING = 'toggles.theme.tooltip';
export const TOGGLE_VIEW_LIST_CHART_TOOLTIP_MAPPING =
  'toggles.view.listChart.tooltip';

export type MoleculesLocaleNamespaceMappings =
  | typeof BUTTON_CONTACT_TEXT_MAPPING
  | typeof BUTTON_FILTER_TOOLTIP_MAPPING
  | typeof BUTTON_EXPERIENCE_TOOLTIP_MAPPING
  | typeof CARD_ACTIVITY_TYPE_PERSONAL_MAPPING
  | typeof CARD_ACTIVITY_TYPE_COMMERCIAL_MAPPING
  | typeof CARD_ACTIVITY_TYPE_STARTUP_MAPPING
  | typeof CARD_ACTIVITY_TYPE_INTERNSHIP_MAPPING
  | typeof CARD_ACTIVITY_TYPE_EDUCATIONAL_MAPPING
  | typeof CHART_SKILLS_DEFAULT_TITLE_MAPPING
  | typeof CHIP_FILTER_PLACEHOLDER_TEXT_MAPPING
  | typeof CHIP_FILTER_PREFIX_ACTIVITY_TYPE_MAPPING
  | typeof CHIP_FILTER_PREFIX_ACTIVITY_POSITION_MAPPING
  | typeof CHIP_FILTER_PREFIX_ACTIVITY_PERIOD_MAPPING
  | typeof CHIP_FILTER_PREFIX_ACTIVITY_PERIOD_LAST_YEAR_MAPPING
  | typeof CHIP_FILTER_PREFIX_ACTIVITY_PERIOD_LAST_FIVE_YEARS_MAPPING
  | typeof CHIP_FILTER_PREFIX_ACTIVITY_PERIOD_UNKNOWN_MAPPING
  | typeof CHIP_FILTER_PREFIX_ACTIVITY_PERIOD_CUSTOM_FROM_MAPPING
  | typeof CHIP_FILTER_PREFIX_ACTIVITY_PERIOD_CUSTOM_TO_MAPPING
  | typeof CHIP_FILTER_PREFIX_ACTIVITY_PERIOD_CUSTOM_UNTIL_MAPPING
  | typeof DIALOG_CONTACT_TITLE_MAPPING
  | typeof DIALOG_CONTACT_CLOSE_MAPPING
  | typeof DIALOG_CONTACT_REGULAR_ALERT_TEXT_MAPPING
  | typeof DIALOG_CONTACT_WARNING_ALERT_TITLE_MAPPING
  | typeof DIALOG_CONTACT_WARNING_ALERT_TEXT_MAPPING
  | typeof DIALOG_FILTER_TITLE_MAPPING
  | typeof DIALOG_FILTER_CLOSE_MAPPING
  | typeof DIALOG_FILTER_APPLY_MAPPING
  | typeof DIALOG_FILTER_RESET_ALL_MAPPING
  | typeof DIALOG_FILTER_ACCORDION_RESET_MAPPING
  | typeof DIALOG_FILTER_ACCORDION_ACTIVITY_TYPE_TITLE_MAPPING
  | typeof DIALOG_FILTER_ACCORDION_ACTIVITY_POSITION_TITLE_MAPPING
  | typeof DIALOG_FILTER_ACCORDION_POSITION_SPECIALIZATION_TITLE_MAPPING
  | typeof DIALOG_FILTER_ACCORDION_ACTIVITY_PERIOD_TITLE_MAPPING
  | typeof DIALOG_FILTER_ACCORDION_ACTIVITY_PERIOD_NONE_MAPPING
  | typeof DIALOG_FILTER_ACCORDION_ACTIVITY_PERIOD_LAST_YEAR_MAPPING
  | typeof DIALOG_FILTER_ACCORDION_ACTIVITY_PERIOD_LAST_FIVE_YEARS_MAPPING
  | typeof DIALOG_FILTER_ACCORDION_ACTIVITY_PERIOD_CUSTOM_TITLE_MAPPING
  | typeof DIALOG_FILTER_ACCORDION_ACTIVITY_PERIOD_CUSTOM_FROM_MAPPING
  | typeof DIALOG_FILTER_ACCORDION_ACTIVITY_PERIOD_CUSTOM_TO_MAPPING
  | typeof TEXT_TRUNCATABLE_READ_MORE_MAPPING
  | typeof TEXT_COPYABLE_TOOLTIP_SUCCESS_MAPPING
  | typeof TEXT_COPYABLE_TOOLTIP_FAILURE_MAPPING
  | typeof TOGGLE_THEME_TOOLTIP_MAPPING
  | typeof TOGGLE_VIEW_LIST_CHART_TOOLTIP_MAPPING;
