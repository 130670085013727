import { LocaleNamespace } from '../LocaleNamespace';
import { LOCALE_NAMESPACE_NAME } from '../LocaleNamespaceName';
import {
  BUTTON_CONTACT_TEXT_MAPPING,
  MoleculesLocaleNamespaceMappings,
} from './MoleculesLocaleNamespaceMappings';

export type MoleculesLocaleNamespace = {
  buttons: {
    contact: {
      text: string;
    };
    filter: {
      tooltip: string;
    };
    experience: {
      tooltip: string;
    };
  };
  cards: {
    activity: {
      type: {
        personal: string;
        commercial: string;
        startup: string;
        internship: string;
        education: string;
      };
    };
  };
  charts: {
    skills: {
      default: {
        title: string;
      };
    };
  };
  chips: {
    filter: {
      placeholder: {
        text: string;
      };
      prefix: {
        activityType: string;
        activityPosition: string;
        activityPeriod: string;
      };
      activityPeriod: {
        unknown: string;
        lastYear: string;
        lastFiveYears: string;
        custom: {
          from: string;
          to: string;
          until: string;
        };
      };
    };
  };
  dialogs: {
    contact: {
      title: string;
      close: string;
      alerts: {
        regular: {
          text: string;
        };
        warning: {
          title: string;
          text: string;
        };
      };
    };
    filter: {
      title: string;
      close: string;
      apply: string;
      resetAll: string;
      accordion: {
        reset: string;
        activityType: {
          title: string;
        };
        activityPosition: {
          title: string;
        };
        positionSpecialization: {
          title: string;
        };
        activityPeriod: {
          title: string;
          none: string;
          lastYear: string;
          lastFiveYears: string;
          custom: {
            title: string;
            from: string;
            to: string;
          };
        };
      };
    };
  };
  text: {
    truncatable: {
      readMore: string;
    };
    copyable: {
      tooltip: {
        success: string;
        failure: string;
      };
    };
  };
  toggles: {
    theme: {
      tooltip: string;
    };
    view: {
      listChart: {
        tooltip: string;
      };
    };
  };
};

export const MOLECULES_LOCALE_NAMESPACE: LocaleNamespace<MoleculesLocaleNamespaceMappings> =
  {
    name: LOCALE_NAMESPACE_NAME.MOLECULES,
    mappings: BUTTON_CONTACT_TEXT_MAPPING,
  };
