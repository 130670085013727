import ThemedHexPaletteCommon from '../../../../../types/common/media/palette/hex/variants/ThemedHexPaletteCommon';

/**
 Default palette:
 '#000000',
 '#000033',
 '#000066',
 '#000099',
 '#0000cc',
 '#0000ff',
 '#006600',
 '#006633',
 '#006666',
 '#006699',
 '#0066cc',
 '#0066ff',
 '#00cc00',
 '#00cc33',
 '#00cc66',
 '#00cc99',
 '#00cccc',
 '#00ccff',
 '#330000',
 '#330033',
 '#330066',
 '#330099',
 '#3300cc',
 '#3300ff',
 '#336600',
 '#336633',
 '#336666',
 '#336699',
 '#3366cc',
 '#3366ff',
 '#33cc00',
 '#33cc33',
 '#33cc66',
 '#33cc99',
 '#33cccc',
 '#33ccff',
 '#660000',
 '#660033',
 '#660066',
 '#660099',
 '#6600cc',
 '#6600ff',
 '#666600',
 '#666633',
 '#666666',
 '#666699',
 '#6666cc',
 '#6666ff',
 '#66cc00',
 '#66cc33',
 '#66cc66',
 '#66cc99',
 '#66cccc',
 '#66ccff',
 '#990000',
 '#990033',
 '#990066',
 '#990099',
 '#9900cc',
 '#9900ff',
 '#996600',
 '#996633',
 '#996666',
 '#996699',
 '#9966cc',
 '#9966ff',
 '#99cc00',
 '#99cc33',
 '#99cc66',
 '#99cc99',
 '#99cccc',
 '#99ccff',
 '#cc0000',
 '#cc0033',
 '#cc0066',
 '#cc0099',
 '#cc00cc',
 '#cc00ff',
 '#cc6600',
 '#cc6633',
 '#cc6666',
 '#cc6699',
 '#cc66cc',
 '#cc66ff',
 '#cccc00',
 '#cccc33',
 '#cccc66',
 '#cccc99',
 '#cccccc',
 '#ccccff',
 '#ff0000',
 '#ff0033',
 '#ff0066',
 '#ff0099',
 '#ff00cc',
 '#ff00ff',
 '#ff6600',
 '#ff6633',
 '#ff6666',
 '#ff6699',
 '#ff66cc',
 '#ff66ff',
 '#ffcc00',
 '#ffcc33',
 '#ffcc66',
 '#ffcc99',
 '#ffcccc',
 '#ffccff',
 '#003300',
 '#003333',
 '#003366',
 '#003399',
 '#0033cc',
 '#0033ff',
 '#009900',
 '#009933',
 '#009966',
 '#009999',
 '#0099cc',
 '#0099ff',
 '#00ff00',
 '#00ff33',
 '#00ff66',
 '#00ff99',
 '#00ffcc',
 '#00ffff',
 '#333300',
 '#333333',
 '#333366',
 '#333399',
 '#3333cc',
 '#3333ff',
 '#339900',
 '#339933',
 '#339966',
 '#339999',
 '#3399cc',
 '#3399ff',
 '#33ff00',
 '#33ff33',
 '#33ff66',
 '#33ff99',
 '#33ffcc',
 '#33ffff',
 '#663300',
 '#663333',
 '#663366',
 '#663399',
 '#6633cc',
 '#6633ff',
 '#669900',
 '#669933',
 '#669966',
 '#669999',
 '#6699cc',
 '#6699ff',
 '#66ff00',
 '#66ff33',
 '#66ff66',
 '#66ff99',
 '#66ffcc',
 '#66ffff',
 '#993300',
 '#993333',
 '#993366',
 '#993399',
 '#9933cc',
 '#9933ff',
 '#999900',
 '#999933',
 '#999966',
 '#999999',
 '#9999cc',
 '#9999ff',
 '#99ff00',
 '#99ff33',
 '#99ff66',
 '#99ff99',
 '#99ffcc',
 '#99ffff',
 '#cc3300',
 '#cc3333',
 '#cc3366',
 '#cc3399',
 '#cc33cc',
 '#cc33ff',
 '#cc9900',
 '#cc9933',
 '#cc9966',
 '#cc9999',
 '#cc99cc',
 '#cc99ff',
 '#ccff00',
 '#ccff33',
 '#ccff66',
 '#ccff99',
 '#ccffcc',
 '#ccffff',
 '#ff3300',
 '#ff3333',
 '#ff3366',
 '#ff3399',
 '#ff33cc',
 '#ff33ff',
 '#ff9900',
 '#ff9933',
 '#ff9966',
 '#ff9999',
 '#ff99cc',
 '#ff99ff',
 '#ffff00',
 '#ffff33',
 '#ffff66',
 '#ffff99',
 '#ffffcc',
 '#ffffff',
 '#111111',
 '#222222',
 '#444444',
 '#555555',
 '#777777',
 '#888888',
 '#aaaaaa',
 '#bbbbbb',
 '#dddddd',
 '#eeeeee',
 '#110000',
 '#220000',
 '#440000',
 '#550000',
 '#770000',
 '#880000',
 '#aa0000',
 '#bb0000',
 '#dd0000',
 '#ee0000',
 '#001100',
 '#002200',
 '#004400',
 '#005500',
 '#007700',
 '#008800',
 '#00aa00',
 '#00bb00',
 '#00dd00',
 '#00ee00',
 '#000011',
 '#000022',
 '#000044',
 '#000055',
 '#000077',
 '#000088',
 '#0000aa',
 '#0000bb',
 '#0000dd',
 '#0000ee',

 Removed colors:
 '#000000',
 '#220000',
 '#110000',
 '#001100',
 '#002200',
 '#000011',
 '#000022',
 '#111111',
 '#222222',
 '#444444',
 '#333333',
 '#555555',
 '#666666',
 '#777777',
 '#888888',
 '#999999',
 '#aaaaaa',
 '#bbbbbb',
 '#cccccc',
 '#dddddd',
 '#eeeeee',
 '#ffffff',

 Result:
 256 - 22 = 234 color Palette
 */
export const CHARTS_APP_SUB_PALETTE: ThemedHexPaletteCommon = {
  light: [
    '#003366',
    '#ffff66',
    '#0000ee',
    '#66cc99',
    '#33cccc',
    '#007700',
    '#3300ff',
    '#6699cc',
    '#ff66cc',
    '#003333',
    '#66ff99',
    '#336633',
    '#ccff33',
    '#3333ff',
    '#993366',
    '#000066',
    '#66ffcc',
    '#cc9933',
    '#333399',
    '#99ffcc',
    '#cc0033',
    '#009933',
    '#663333',
    '#003300',
    '#9900cc',
    '#ff00cc',
    '#9966ff',
    '#880000',
    '#cc6633',
    '#336699',
    '#339966',
    '#cc9900',
    '#99cc99',
    '#009900',
    '#ff0000',
    '#33cc00',
    '#9966cc',
    '#cc0000',
    '#ccccff',
    '#999966',
    '#660000',
    '#00dd00',
    '#993300',
    '#ffff00',
    '#996633',
    '#99ff00',
    '#cc3366',
    '#333366',
    '#9999ff',
    '#33ff00',
    '#006699',
    '#cccc00',
    '#0000dd',
    '#ccff99',
    '#00ccff',
    '#66ff33',
    '#cc0099',
    '#669933',
    '#000055',
    '#ff99ff',
    '#0000bb',
    '#66ff66',
    '#bb0000',
    '#669999',
    '#663399',
    '#cccc99',
    '#996699',
    '#99ccff',
    '#33ff33',
    '#ff33cc',
    '#996600',
    '#cc3300',
    '#66ff00',
    '#333300',
    '#ff9966',
    '#cc00cc',
    '#ff0066',
    '#660066',
    '#ff6666',
    '#666699',
    '#0066ff',
    '#66cc00',
    '#ff6699',
    '#00cc99',
    '#6600ff',
    '#00cccc',
    '#00cc00',
    '#770000',
    '#0000ff',
    '#440000',
    '#339933',
    '#33ccff',
    '#ee0000',
    '#ccffcc',
    '#330066',
    '#009966',
    '#cc66cc',
    '#000077',
    '#ff99cc',
    '#ffcc66',
    '#0000aa',
    '#99cc00',
    '#9900ff',
    '#3333cc',
    '#ff33ff',
    '#6600cc',
    '#ff3366',
    '#0099ff',
    '#336666',
    '#ff00ff',
    '#005500',
    '#cc99cc',
    '#99ff99',
    '#ffcc33',
    '#ccffff',
    '#cc9966',
    '#ff3399',
    '#3399ff',
    '#cc0066',
    '#cc99ff',
    '#330000',
    '#3300cc',
    '#ffffcc',
    '#cc33ff',
    '#00bb00',
    '#663300',
    '#669900',
    '#009999',
    '#ff3333',
    '#99cc33',
    '#0033ff',
    '#339999',
    '#ff6600',
    '#ff9999',
    '#66cc66',
    '#000099',
    '#99ff66',
    '#6633cc',
    '#ffff99',
    '#669966',
    '#00ee00',
    '#cc33cc',
    '#000033',
    '#33cc99',
    '#0099cc',
    '#cc3333',
    '#00cc33',
    '#993399',
    '#6666ff',
    '#33cc66',
    '#6666cc',
    '#0000cc',
    '#cc3399',
    '#999900',
    '#00cc66',
    '#990000',
    '#cc6699',
    '#9999cc',
    '#cc9999',
    '#006600',
    '#00ffcc',
    '#0033cc',
    '#ffcccc',
    '#00ff66',
    '#66cccc',
    '#3366ff',
    '#66cc33',
    '#ff3300',
    '#9933cc',
    '#006666',
    '#99cc66',
    '#ffcc00',
    '#330099',
    '#00ff00',
    '#cccc66',
    '#ff6633',
    '#00aa00',
    '#6633ff',
    '#993333',
    '#99ffff',
    '#cc66ff',
    '#006633',
    '#000088',
    '#666600',
    '#663366',
    '#cccc33',
    '#33ff99',
    '#dd0000',
    '#996666',
    '#ccff00',
    '#660099',
    '#008800',
    '#990033',
    '#99cccc',
    '#990099',
    '#003399',
    '#336600',
    '#999933',
    '#66ffff',
    '#3366cc',
    '#ffff33',
    '#550000',
    '#99ff33',
    '#cc6666',
    '#9933ff',
    '#000044',
    '#ff9933',
    '#ff0033',
    '#33cc33',
    '#0066cc',
    '#ff9900',
    '#33ffcc',
    '#330033',
    '#66ccff',
    '#666633',
    '#33ffff',
    '#990066',
    '#00ff99',
    '#004400',
    '#cc6600',
    '#660033',
    '#ccff66',
    '#ff0099',
    '#00ff33',
    '#6699ff',
    '#ffcc99',
    '#aa0000',
    '#ff66ff',
    '#00ffff',
    '#3399cc',
    '#cc00ff',
    '#33ff66',
    '#ffccff',
    '#339900',
  ],
  dark: [
    '#003366',
    '#ffff66',
    '#0000ee',
    '#66cc99',
    '#33cccc',
    '#007700',
    '#3300ff',
    '#6699cc',
    '#ff66cc',
    '#003333',
    '#66ff99',
    '#336633',
    '#ccff33',
    '#3333ff',
    '#993366',
    '#000066',
    '#66ffcc',
    '#cc9933',
    '#333399',
    '#99ffcc',
    '#cc0033',
    '#009933',
    '#663333',
    '#003300',
    '#9900cc',
    '#ff00cc',
    '#9966ff',
    '#880000',
    '#cc6633',
    '#336699',
    '#339966',
    '#cc9900',
    '#99cc99',
    '#009900',
    '#ff0000',
    '#33cc00',
    '#9966cc',
    '#cc0000',
    '#ccccff',
    '#999966',
    '#660000',
    '#00dd00',
    '#993300',
    '#ffff00',
    '#996633',
    '#99ff00',
    '#cc3366',
    '#333366',
    '#9999ff',
    '#33ff00',
    '#006699',
    '#cccc00',
    '#0000dd',
    '#ccff99',
    '#00ccff',
    '#66ff33',
    '#cc0099',
    '#669933',
    '#000055',
    '#ff99ff',
    '#0000bb',
    '#66ff66',
    '#bb0000',
    '#669999',
    '#663399',
    '#cccc99',
    '#996699',
    '#99ccff',
    '#33ff33',
    '#ff33cc',
    '#996600',
    '#cc3300',
    '#66ff00',
    '#333300',
    '#ff9966',
    '#cc00cc',
    '#ff0066',
    '#660066',
    '#ff6666',
    '#666699',
    '#0066ff',
    '#66cc00',
    '#ff6699',
    '#00cc99',
    '#6600ff',
    '#00cccc',
    '#00cc00',
    '#770000',
    '#0000ff',
    '#440000',
    '#339933',
    '#33ccff',
    '#ee0000',
    '#ccffcc',
    '#330066',
    '#009966',
    '#cc66cc',
    '#000077',
    '#ff99cc',
    '#ffcc66',
    '#0000aa',
    '#99cc00',
    '#9900ff',
    '#3333cc',
    '#ff33ff',
    '#6600cc',
    '#ff3366',
    '#0099ff',
    '#336666',
    '#ff00ff',
    '#005500',
    '#cc99cc',
    '#99ff99',
    '#ffcc33',
    '#ccffff',
    '#cc9966',
    '#ff3399',
    '#3399ff',
    '#cc0066',
    '#cc99ff',
    '#330000',
    '#3300cc',
    '#ffffcc',
    '#cc33ff',
    '#00bb00',
    '#663300',
    '#669900',
    '#009999',
    '#ff3333',
    '#99cc33',
    '#0033ff',
    '#339999',
    '#ff6600',
    '#ff9999',
    '#66cc66',
    '#000099',
    '#99ff66',
    '#6633cc',
    '#ffff99',
    '#669966',
    '#00ee00',
    '#cc33cc',
    '#000033',
    '#33cc99',
    '#0099cc',
    '#cc3333',
    '#00cc33',
    '#993399',
    '#6666ff',
    '#33cc66',
    '#6666cc',
    '#0000cc',
    '#cc3399',
    '#999900',
    '#00cc66',
    '#990000',
    '#cc6699',
    '#9999cc',
    '#cc9999',
    '#006600',
    '#00ffcc',
    '#0033cc',
    '#ffcccc',
    '#00ff66',
    '#66cccc',
    '#3366ff',
    '#66cc33',
    '#ff3300',
    '#9933cc',
    '#006666',
    '#99cc66',
    '#ffcc00',
    '#330099',
    '#00ff00',
    '#cccc66',
    '#ff6633',
    '#00aa00',
    '#6633ff',
    '#993333',
    '#99ffff',
    '#cc66ff',
    '#006633',
    '#000088',
    '#666600',
    '#663366',
    '#cccc33',
    '#33ff99',
    '#dd0000',
    '#996666',
    '#ccff00',
    '#660099',
    '#008800',
    '#990033',
    '#99cccc',
    '#990099',
    '#003399',
    '#336600',
    '#999933',
    '#66ffff',
    '#3366cc',
    '#ffff33',
    '#550000',
    '#99ff33',
    '#cc6666',
    '#9933ff',
    '#000044',
    '#ff9933',
    '#ff0033',
    '#33cc33',
    '#0066cc',
    '#ff9900',
    '#33ffcc',
    '#330033',
    '#66ccff',
    '#666633',
    '#33ffff',
    '#990066',
    '#00ff99',
    '#004400',
    '#cc6600',
    '#660033',
    '#ccff66',
    '#ff0099',
    '#00ff33',
    '#6699ff',
    '#ffcc99',
    '#aa0000',
    '#ff66ff',
    '#00ffff',
    '#3399cc',
    '#cc00ff',
    '#33ff66',
    '#ffccff',
    '#339900',
  ],
};
