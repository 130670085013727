import Specialization from '../../../../types/business/specialization/Specialization';
import SpecializationId from '../../../../types/business/specialization/SpecializationId';
import { PositionExperience } from './calculatePositionsExperience';
import Duration from '../../../timedate/duration/Duration';

import deepClone from '../../../utils/deepClone';
import sumDurations from '../../../timedate/duration/utils/sumDurations';

export type PositionSpecializationExperience = {
  specialization: Specialization;
  experience: Duration;
  positionExperiences: PositionExperience[];
};

type PositionSpecializationRawExperience = {
  specialization: Specialization;
  positionExperiences: PositionExperience[];
};

const groupExperienceByUniqueSpecializations = (
  positionsExperience: PositionExperience[],
) => {
  const positionSpecializationsExperience = new Map<
    SpecializationId,
    PositionSpecializationRawExperience
  >();

  positionsExperience.forEach((positionExperience) => {
    if (
      positionSpecializationsExperience.has(
        positionExperience.position.specialization.id,
      )
    ) {
      positionSpecializationsExperience.set(
        positionExperience.position.specialization.id,
        {
          specialization: positionExperience.position.specialization,
          positionExperiences: [
            positionExperience,
            ...((
              positionSpecializationsExperience.get(
                positionExperience.position.specialization.id,
              ) as PositionSpecializationRawExperience
            ).positionExperiences as PositionExperience[]),
          ],
        },
      );
    } else {
      positionSpecializationsExperience.set(
        positionExperience.position.specialization.id,
        {
          specialization: positionExperience.position.specialization,
          positionExperiences: [positionExperience],
        },
      );
    }
  });

  return Array.from(positionSpecializationsExperience.values());
};

const calculateExperienceOfUniqueSpecializations = (
  positionSpecializationsExperience: PositionSpecializationRawExperience[],
): PositionSpecializationExperience[] => {
  const positionSpecializationsExperiences: PositionSpecializationExperience[] =
    [];

  positionSpecializationsExperience.forEach(
    (positionSpecializationExperience) => {
      const positionExperienceDurations: Duration[] =
        positionSpecializationExperience.positionExperiences.map(
          (positionExperience) => positionExperience.experience,
        );

      positionSpecializationsExperiences.push({
        specialization: positionSpecializationExperience.specialization,
        experience: sumDurations(positionExperienceDurations),
        positionExperiences:
          positionSpecializationExperience.positionExperiences,
      });
    },
  );

  return positionSpecializationsExperiences;
};

const calculatePositionSpecializationsExperience = (
  positionsExperience: PositionExperience[],
): PositionSpecializationExperience[] => {
  const positionsExperienceClone = deepClone(positionsExperience);
  const rawPositionSpecializationsExperience =
    groupExperienceByUniqueSpecializations(positionsExperienceClone);
  return calculateExperienceOfUniqueSpecializations(
    rawPositionSpecializationsExperience,
  );
};

export default calculatePositionSpecializationsExperience;
