import ImageCommonSource from '../ImageCommonSource';
import ImageCommonThemedSource from '../variants/ImageCommonThemedSource';

import isThemed from '../../../../guards/isThemed';

const isImageSourceThemed = (
  source: ImageCommonSource,
): source is ImageCommonThemedSource => isThemed(source);

export default isImageSourceThemed;
