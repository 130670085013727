import PositionsResource from '../../../types/business/positions/PositionsResource';
import ProcessedPositionsResource from '../../../types/business/positions/processed/ProcessedPositionsResource';
import SpecializationsResource from '../../../types/business/specializations/SpecializationsResource';
import ThemedHexPaletteCommon from '../../../../../types/common/media/palette/hex/variants/ThemedHexPaletteCommon';

import ResourceProcessResult from '../../types/result/ResourceProcessResult';
import { FAILURE_RESOURCE_PROCESS_RESULT } from '../../types/result/variants/FailureResourceProcessResult';

import processResourceWithPalette from '../../common/processResourceWithPalette';

const processPositionsResourceWithPalette = (
  positionsResource: PositionsResource,
  specializationsResource: SpecializationsResource | null,
  palette: ThemedHexPaletteCommon,
): ResourceProcessResult<ProcessedPositionsResource> => {
  if (specializationsResource) {
    const trunkedLightPaletteSize = Math.min(
      palette.light.length,
      specializationsResource.length,
    );
    const trunkedDarkPaletteSize = Math.min(
      palette.dark.length,
      specializationsResource.length,
    );
    const positionsPalette = {
      light: palette.light.slice(trunkedLightPaletteSize),
      dark: palette.dark.slice(trunkedDarkPaletteSize),
    };

    return {
      success: true,
      resource: processResourceWithPalette(positionsResource, positionsPalette),
    };
  }

  return FAILURE_RESOURCE_PROCESS_RESULT;
};

export default processPositionsResourceWithPalette;
