import SkillId from '../../../../../../../skill/SkillId';
import SkillName from '../../../../../../../skill/SkillName';
import SkillAvatar from '../../../../../../../skill/SkillAvatar';
import ProcessedSkillResource from '../../../../../../../../../tools/resources/types/business/skills/processed/ProcessedSkillResource';
import ConstructedCvSubactivitySkill from './constructed/ConstructedCvSubactivitySkill';
import CvSkillTag from './fields/tags/CvSkillTag';
import SkillColor from '../../../../../../../skill/SkillColor';

type CvSubactivitySkill = {
  id: SkillId;
  name: SkillName;
  avatar?: SkillAvatar;
  color: SkillColor;
  tags?: CvSkillTag[];
};

export const createSkillFromResource = (
  skillResource: ProcessedSkillResource,
): ConstructedCvSubactivitySkill => ({
  id: skillResource.id,
  name: skillResource.name,
  avatar: skillResource.avatar,
  color: skillResource.color,
  tags: skillResource.tagIds,
});

export default CvSubactivitySkill;
