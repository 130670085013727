import { useSelector } from 'react-redux';

import { createTheme } from '@mui/material';

import { RootState } from '../../../storage/reducers/rootReducer';

import AppTheme from '../../../types/app/theme/AppTheme';

interface UseAppTheme {
  appTheme: AppTheme;
}

const useAppTheme = (): UseAppTheme => {
  const { theme } = useSelector((state: RootState) => state.appReducer);

  return {
    appTheme: createTheme(theme.palette),
  };
};

export default useAppTheme;
