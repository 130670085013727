import SpecializationsResource from '../../../types/business/specializations/SpecializationsResource';
import ProcessedSpecializationsResource from '../../../types/business/specializations/processed/ProcessedSpecializationsResource';
import ThemedHexPaletteCommon from '../../../../../types/common/media/palette/hex/variants/ThemedHexPaletteCommon';

import ResourceProcessResult from '../../types/result/ResourceProcessResult';

import processResourceWithPalette from '../../common/processResourceWithPalette';

const processSpecializationsResourceWithPalette = (
  specializationsResource: SpecializationsResource,
  palette: ThemedHexPaletteCommon,
): ResourceProcessResult<ProcessedSpecializationsResource> => {
  const trunkedLightPaletteSize = Math.min(
    palette.light.length,
    specializationsResource.length,
  );
  const trunkedDarkPaletteSize = Math.min(
    palette.dark.length,
    specializationsResource.length,
  );
  const specializationsPalette = {
    light: palette.light.slice(0, trunkedLightPaletteSize),
    dark: palette.dark.slice(0, trunkedDarkPaletteSize),
  };

  return {
    success: true,
    resource: processResourceWithPalette(
      specializationsResource,
      specializationsPalette,
    ),
  };
};

export default processSpecializationsResourceWithPalette;
