import React from 'react';

import { Tooltip, TooltipProps } from '@mui/material';

type TooltipAtomProps = TooltipProps & {
  disabled?: boolean;
  xOffset?: number;
  yOffset?: number;
};

const TooltipAtom: React.FC<TooltipAtomProps> = ({
  className,
  title,
  arrow,
  open,
  onClose,
  onMouseEnter,
  onMouseLeave,
  disabled = false,
  disableHoverListener,
  disableFocusListener,
  disableTouchListener,
  enterDelay,
  leaveDelay,
  xOffset = 0,
  yOffset = 0,
  children,
}) => (
  <Tooltip
    className={className}
    title={title}
    arrow={arrow}
    open={open}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    disableHoverListener={disabled || disableHoverListener}
    disableFocusListener={disabled || disableFocusListener}
    disableTouchListener={disabled || disableTouchListener}
    enterDelay={enterDelay}
    leaveDelay={leaveDelay}
    onClose={onClose}
    slotProps={{
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [xOffset, yOffset],
            },
          },
        ],
      },
    }}>
    {children}
  </Tooltip>
);

export default TooltipAtom;
