import ProcessedPositionResource from '../../../../../../../../../tools/resources/types/business/positions/processed/ProcessedPositionResource';
import ConstructedCvActivityPosition from '../ConstructedCvActivityPosition';

const createConstructedActivityPositionFromResource = (
  positionResource?: ProcessedPositionResource,
): ConstructedCvActivityPosition | undefined =>
  positionResource && {
    id: positionResource.id,
    name: positionResource.name,
    color: positionResource.color,
    specialization: positionResource.specializationId,
  };

export default createConstructedActivityPositionFromResource;
