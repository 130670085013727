import { useEffect } from 'react';

import useStaticResourcesLoader from '../../resources/useStaticResourcesLoader';
import useStaticResources from '../../resources/useStaticResources';

/**
 * @see useCvLoader - Producer of the useCvLoaderEffects events
 */
const useCvLoaderEffects = () => {
  const {
    loadUserResource,
    loadActivitiesResource,
    loadSubactivitiesResource,
    loadProjectsResource,
    loadProvidersResource,
    loadPositionsResource,
    loadSkillsResource,
    loadTagsResource,
    loadSpecializationsResource,
  } = useStaticResourcesLoader();
  const {
    cvBase,
    userBase,
    activitiesBase,
    subactivitiesBase,
    projectsBase,
    providersBase,
    positionsBase,
    skillsBase,
    tagsBase,
    specializationsBase,
  } = useStaticResources();

  // Load of missing static resources based on a single requester
  useEffect(() => {
    if (cvBase && !userBase) {
      loadUserResource();
    }
  }, [cvBase, userBase, loadUserResource]);

  useEffect(() => {
    if (cvBase && !activitiesBase) {
      loadActivitiesResource();
    }
  }, [cvBase, activitiesBase, loadActivitiesResource]);

  useEffect(() => {
    if (activitiesBase && !projectsBase) {
      loadProjectsResource();
    }
  }, [activitiesBase, projectsBase, loadProjectsResource]);

  useEffect(() => {
    if (activitiesBase && !providersBase) {
      loadProvidersResource();
    }
  }, [activitiesBase, providersBase, loadProvidersResource]);

  useEffect(() => {
    if (activitiesBase && !subactivitiesBase) {
      loadSubactivitiesResource();
    }
  }, [activitiesBase, subactivitiesBase, loadSubactivitiesResource]);

  useEffect(() => {
    if (positionsBase && !skillsBase) {
      loadSkillsResource();
    }
  }, [positionsBase, skillsBase, loadSkillsResource]);

  useEffect(() => {
    if (positionsBase && !specializationsBase) {
      loadSpecializationsResource();
    }
  }, [positionsBase, specializationsBase, loadSpecializationsResource]);

  // Load of missing static resources based on multiple requesters
  useEffect(() => {
    if ((userBase || activitiesBase) && !positionsBase) {
      loadPositionsResource();
    }
  }, [userBase, activitiesBase, positionsBase, loadPositionsResource]);

  useEffect(() => {
    if ((userBase || skillsBase) && !tagsBase) {
      loadTagsResource();
    }
  }, [userBase, skillsBase, tagsBase, loadTagsResource]);
};

export default useCvLoaderEffects;
