import React, { useMemo } from 'react';

import { Box, Stack } from '@mui/material';

import useI18n from '../../../../../hooks/internationalization/useI18n';
import useCv from '../../../../../hooks/business/cv/useCv';

import Experience from '../../../../../types/business/experience/Experience';
import ExperienceGroup from '../../../../../types/business/experience/group/ExperienceGroup';

import calculateSkillsExperience from '../../../../../tools/business/cv/calculate/calculateSkillsExperience';
import sortSkillsExperience from '../../../../../tools/business/cv/sort/sortSkillsExperience';
import groupSkillsExperienceByTagGroupsWithDuplicates from '../../../../../tools/business/cv/group/groupSkillsExperienceByTagGroupsWithDuplicates';

import { ComponentProps } from '../../../ComponentProps';
import LoadingBackgroundMolecule from '../../../molecules/background/loading/LoadingBackgroundMolecule';
import EmptyPlaceholderMolecule from '../../../molecules/placeholders/empty/EmptyPlaceholderMolecule';
import SkillsCardMolecule from '../../../molecules/cards/skills/SkillsCardMolecule';

import { ORGANISMS_LOCALE_NAMESPACE } from '../../../../../tools/internationalization/i18n/localization/namespaces/organisms/OrganismsLocaleNamespace';
import { CV_SKILLS_EMPTY_MAPPING } from '../../../../../tools/internationalization/i18n/localization/namespaces/organisms/OrganismsLocaleNamespaceMappings';

type CvSkillsOrganismProps = ComponentProps;

const CvSkillsOrganism: React.FC<CvSkillsOrganismProps> = ({
  className = 'cv-skills-organism',
}) => {
  const { t } = useI18n(ORGANISMS_LOCALE_NAMESPACE);
  const { cvUser, cvActivities } = useCv();

  const experienceGroups: ExperienceGroup[] | undefined = useMemo(() => {
    if (cvUser && cvActivities) {
      if (cvActivities.length === 0) {
        return [];
      }

      const isSubactivitiesAvailable = cvActivities.some(
        (activity) =>
          activity.subactivities && activity.subactivities.length > 0,
      );

      const skillsExperience: Experience[] = [];
      if (isSubactivitiesAvailable) {
        skillsExperience.push(...calculateSkillsExperience(cvActivities));
      }

      if (skillsExperience.length > 0) {
        const experiencePreferences = cvUser.preferences?.tagGroups ?? [];
        const skillExperienceGroups: ExperienceGroup[] =
          groupSkillsExperienceByTagGroupsWithDuplicates(
            experiencePreferences,
            calculateSkillsExperience(cvActivities),
          );

        skillExperienceGroups.forEach((skillExperienceGroup) => {
          skillExperienceGroup.experiences = sortSkillsExperience(
            skillExperienceGroup.experiences,
          );
        });

        return skillExperienceGroups;
      }
    }
    return undefined;
  }, [cvUser, cvActivities]);

  const renderSkillCards = (loadedExperienceGroups: ExperienceGroup[]) => (
    <Stack
      className='cv-skills__cards'
      width='100%'
      direction='column'
      spacing={2}>
      {loadedExperienceGroups.map((experienceGroup) => (
        <SkillsCardMolecule
          className='cv-skills__card'
          key={experienceGroup.id}
          tagGroup={experienceGroup.tagGroup}
          experience={experienceGroup.experiences}
        />
      ))}
    </Stack>
  );

  const renderSkills = (loadedExperienceGroups: ExperienceGroup[]) =>
    loadedExperienceGroups.length > 0 ? (
      renderSkillCards(loadedExperienceGroups)
    ) : (
      <EmptyPlaceholderMolecule
        className='cv-skills__no-skills'
        text={t(CV_SKILLS_EMPTY_MAPPING)}
        width='100%'
        height='auto'
        minHeight='200px'
      />
    );

  const renderLoading = () => (
    <LoadingBackgroundMolecule className='cv-skills__loading' height='auto' />
  );

  return (
    <Box
      className={`${className} cv-skills`}
      width='100%'
      display='flex'
      flexGrow={1}
      alignItems='stretch'>
      {experienceGroups ? renderSkills(experienceGroups) : renderLoading()}
    </Box>
  );
};
export default CvSkillsOrganism;
