import setValue, { SetValue } from '../../setValue';

import ProcessedSkillsResource from '../../../../tools/resources/types/business/skills/processed/ProcessedSkillsResource';

import { LOAD_SKILLS_RESOURCE_SET } from '../resourceActionTypes';

export type SetSkillsResource = SetValue<
  typeof LOAD_SKILLS_RESOURCE_SET,
  ProcessedSkillsResource
>;

const setSkillsResource = (resource: ProcessedSkillsResource) =>
  setValue(LOAD_SKILLS_RESOURCE_SET, resource);

export default setSkillsResource;
