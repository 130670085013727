import Specialization from '../../../../../../../specialization/Specialization';
import ProcessedSpecializationResource from '../../../../../../../../../tools/resources/types/business/specializations/processed/ProcessedSpecializationResource';

type CvActivityPositionSpecialization = Specialization;

export const createCvActivityPositionSpecializationFromResource = (
  specializationResource: ProcessedSpecializationResource,
): CvActivityPositionSpecialization => ({
  id: specializationResource.id,
  name: specializationResource.name,
  color: specializationResource.color,
});

export default CvActivityPositionSpecialization;
