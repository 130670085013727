import ConstructedCvActivityPosition from '../ConstructedCvActivityPosition';
import ProcessedSpecializationsResource from '../../../../../../../../../tools/resources/types/business/specializations/processed/ProcessedSpecializationsResource';
import CvUserPositionSpecializationId from '../../../../../user/fields/position/constructed/fields/CvUserPositionSpecializationId';

import { createCvActivityPositionSpecializationFromResource } from '../../fields/specialization/CvActivityPositionSpecialization';

const updateConstructedActivityPositionWithSpecializationsResource = (
  constructedPosition: ConstructedCvActivityPosition,
  specializationsResource: ProcessedSpecializationsResource,
): ConstructedCvActivityPosition => {
  const foundSpecializationResource = specializationsResource.find(
    (specialization) =>
      specialization.id ===
      (constructedPosition.specialization as CvUserPositionSpecializationId),
  );

  if (!foundSpecializationResource) {
    return constructedPosition;
  }

  return {
    ...constructedPosition,
    specialization: createCvActivityPositionSpecializationFromResource(
      foundSpecializationResource,
    ),
  };
};

export default updateConstructedActivityPositionWithSpecializationsResource;
