import AppThemeState from '../../types/app/theme/state/AppThemeState';
import AppTabsState from '../../types/app/tabs/state/AppTabsState';
import { DARK_THEME_MODE } from '../../types/app/theme/mode/AppThemeMode';
import { DEFAULT_TAB_SCROLL } from '../../types/app/tabs/state/fields/scroll/AppTabScroll';
import {
  ACTIVITIES_APP_TAB_KEY,
  POSITIONS_APP_TAB_KEY,
  SKILLS_APP_TAB_KEY,
} from '../../types/app/tabs/key/AppTabKey';

import { SetAppThemeMode } from '../actions/app/set/theme/setAppThemeMode';
import { SetAppTabScroll } from '../actions/app/set/scroll/setAppTabScroll';
import {
  SET_APP_TAB_SCROLL,
  SET_APP_THEME_MODE,
} from '../actions/app/appActionTypes';

import { buildAppPalette } from '../../tools/theme/app/palette/builders/buildAppPalette';

interface AppStore {
  theme: AppThemeState;
  tabs: AppTabsState;
}

const initialState: AppStore = {
  theme: {
    mode: DARK_THEME_MODE,
    // @ts-ignore
    palette: { palette: buildAppPalette(DARK_THEME_MODE) },
  },
  tabs: {
    [ACTIVITIES_APP_TAB_KEY]: {
      scroll: DEFAULT_TAB_SCROLL,
    },
    [POSITIONS_APP_TAB_KEY]: {
      scroll: DEFAULT_TAB_SCROLL,
    },
    [SKILLS_APP_TAB_KEY]: {
      scroll: DEFAULT_TAB_SCROLL,
    },
  },
};

type AppReducerTypes = SetAppThemeMode | SetAppTabScroll;

export const appReducer = (
  state = initialState,
  action: AppReducerTypes,
): AppStore => {
  switch (action.type) {
    case SET_APP_THEME_MODE: {
      return {
        ...state,
        theme: {
          mode: action.payload,
          palette: buildAppPalette(action.payload),
        },
      };
    }
    case SET_APP_TAB_SCROLL: {
      return {
        ...state,
        tabs: {
          ...state.tabs,
          [action.payload.key]: {
            scroll: action.payload.value,
          },
        },
      };
    }
    default:
      return state;
  }
};
