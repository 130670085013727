import HexColorCommonResource from '../../types/common/HexColorCommonResource';
import ThemedHexPaletteCommon from '../../../../types/common/media/palette/hex/variants/ThemedHexPaletteCommon';

type PossibleColoredResource = {
  color?: HexColorCommonResource;
};

type ColoredResource = Required<PossibleColoredResource>;

const processResourceWithPalette = <T extends PossibleColoredResource>(
  resources: T[],
  palette: ThemedHexPaletteCommon,
): (T & ColoredResource)[] => {
  let paletteIndex = 0;
  resources.forEach((resource) => {
    if (!resource.color) {
      resource.color = {
        light: palette.light[paletteIndex % palette.light.length],
        dark: palette.dark[paletteIndex % palette.dark.length],
      };
      paletteIndex++;
    }
  });

  return resources as (T & ColoredResource)[];
};

export default processResourceWithPalette;
