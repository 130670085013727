import React from 'react';

import { ChipProps } from '@mui/material/Chip/Chip';
import { Chip } from '@mui/material';

type ChipAtomProps = ChipProps & {
  scale?: number;
  deleteIconScale?: number;
  hoverBackgroundColor?: string;
  borderWidth?: string;
  borderStyle?: string;
  borderColor?: string;
  borderColorHover?: string;
  deleteIconColor?: string;
  deleteIconColorHover?: string;
  avatarSx?: object;
  labelSize?: number;
  labelSx?: object;
};

const ChipAtom: React.FC<ChipAtomProps> = ({
  id,
  className,
  variant,
  scale = 1,
  deleteIconScale,
  hoverBackgroundColor,
  borderWidth,
  borderStyle,
  borderColor,
  borderColorHover,
  deleteIconColor,
  deleteIconColorHover,
  avatar,
  avatarSx,
  label,
  labelSx,
  onClick,
  onDelete,
  sx,
}) => {
  const scaledChipSx = {
    height: '100%',
    minHeight: `${scale * 32}px`,
    borderWidth,
    borderRadius: `${scale * 16}px`,
    borderStyle,
    borderColor,
    '&:hover': {
      borderColor: borderColorHover,
      backgroundColor: hoverBackgroundColor,
    },
    '&.MuiChip-clickable': {
      '&:hover': {
        backgroundColor: hoverBackgroundColor,
      },
    },
    '& .MuiChip-label': {
      paddingY: `${scale * 6}px`,
      paddingX: `${scale * 12}px`,
      fontSize: `${scale * 0.875}rem`,
      overflowWrap: 'break-word',
      whiteSpace: 'normal',
      textOverflow: 'clip',
      ...labelSx,
    },
    '& .MuiChip-avatar': {
      width: `${scale * 24}px`,
      height: `${scale * 24}px`,
      fontSize: `${scale * 0.75}rem`,
      ...avatarSx,
    },
    '& .MuiChip-deleteIcon': {
      width: `${(deleteIconScale ?? scale) * 24}px`,
      height: `${(deleteIconScale ?? scale) * 24}px`,
      color: deleteIconColor,
      '&:hover': {
        color: deleteIconColorHover,
      },
    },
    ...sx,
  };

  return (
    <Chip
      id={id}
      className={className}
      variant={variant}
      avatar={avatar}
      label={label}
      sx={scaledChipSx}
      onClick={onClick}
      onDelete={onDelete}
    />
  );
};

export default ChipAtom;
