import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import { RootState } from '../../storage/reducers/rootReducer';
import useRootDispatch from '../../storage/dispatch/rootDispatch';

import { SetValue } from '../../storage/actions/setValue';
import setCvResource from '../../storage/actions/resources/set/setCvResource';
import setUserResource from '../../storage/actions/resources/set/setUserResource';
import setActivitiesResource from '../../storage/actions/resources/set/setActivitiesResource';
import setSubactivitiesResource from '../../storage/actions/resources/set/setSubactivitiesResource';
import setProjectsResource from '../../storage/actions/resources/set/setProjectsResource';
import setProvidersResource from '../../storage/actions/resources/set/setProvidersResource';
import setPositionsResource from '../../storage/actions/resources/set/setPositionsResource';
import setSkillsResource from '../../storage/actions/resources/set/setSkillsResource';
import setTagsResource from '../../storage/actions/resources/set/setTagsResource';
import setSpecializationsResource from '../../storage/actions/resources/set/setSpecializationsResource';

import Resource from '../../tools/resources/types/Resource';
import LoadableResource from '../../tools/resources/types/LoadableResource';
import ResourceProcessResult from '../../tools/resources/processes/types/result/ResourceProcessResult';
import SpecializationsResource from '../../tools/resources/types/business/specializations/SpecializationsResource';
import SkillsResource from '../../tools/resources/types/business/skills/SkillsResource';
import PositionsResource from '../../tools/resources/types/business/positions/PositionsResource';

import processSkillsResourceWithPalette from '../../tools/resources/processes/business/skills/processSkillsResourceWithPalette';
import processSpecializationsResourceWithPalette from '../../tools/resources/processes/business/specializations/processSpecializationsResourceWithPalette';
import processPositionsResourceWithPalette from '../../tools/resources/processes/business/positions/processPositionsResourceWithPalette';

import { CHARTS_APP_SUB_PALETTE } from '../../tools/theme/app/palette/subpalettes/chartsAppSubPalette';
import isResourceProcessResultSuccessful from '../../tools/resources/processes/types/result/guards/isResourceProcessResultSuccessful';
import ProcessedPositionsResource from '../../tools/resources/types/business/positions/processed/ProcessedPositionsResource';

const useStaticResourcesProcessEffects = () => {
  const dispatch = useRootDispatch();

  const {
    cv,
    user,
    activities,
    subactivities,
    projects,
    providers,
    positions,
    skills,
    tags,
    specializations,
  } = useSelector((state: RootState) => state.resourcesReducer);

  const processResource = useCallback(
    <T extends Resource, V extends T>(
      resource: LoadableResource<T>,
      process: (resource: T) => ResourceProcessResult<V>,
      setter: (
        processed: V,
      ) => (dispatch: Dispatch<SetValue<string, V>>) => void,
    ) => {
      if (resource) {
        const result = process(resource);
        if (isResourceProcessResultSuccessful(result)) {
          dispatch(setter(result.resource));
        }
      }
    },
    [dispatch],
  );

  const doNotProcess = useCallback(
    <T extends Resource>(resource: T) => ({
      success: true,
      resource,
    }),
    [],
  );

  useEffect(() => {
    processResource(cv.baseValue, doNotProcess, setCvResource);
  }, [cv.baseValue, doNotProcess, processResource]);

  useEffect(() => {
    processResource(user.baseValue, doNotProcess, setUserResource);
  }, [user.baseValue, processResource, doNotProcess]);

  useEffect(() => {
    processResource(activities.baseValue, doNotProcess, setActivitiesResource);
  }, [activities, doNotProcess, processResource]);

  useEffect(() => {
    processResource(
      subactivities.baseValue,
      doNotProcess,
      setSubactivitiesResource,
    );
  }, [subactivities.baseValue, processResource, doNotProcess]);

  useEffect(() => {
    processResource(projects.baseValue, doNotProcess, setProjectsResource);
  }, [projects.baseValue, processResource, doNotProcess]);

  useEffect(() => {
    processResource(providers.baseValue, doNotProcess, setProvidersResource);
  }, [providers.baseValue, processResource, doNotProcess]);

  useEffect(() => {
    processResource<PositionsResource, ProcessedPositionsResource>(
      positions.baseValue,
      (positionsResource: PositionsResource) =>
        processPositionsResourceWithPalette(
          positionsResource,
          specializations.baseValue,
          CHARTS_APP_SUB_PALETTE,
        ),
      setPositionsResource,
    );
  }, [positions.baseValue, specializations.baseValue, processResource]);

  useEffect(() => {
    processResource(
      skills.baseValue,
      (skillsResource: SkillsResource) =>
        processSkillsResourceWithPalette(
          skillsResource,
          CHARTS_APP_SUB_PALETTE,
        ),
      setSkillsResource,
    );
  }, [skills, processResource]);

  useEffect(() => {
    processResource(tags.baseValue, doNotProcess, setTagsResource);
  }, [tags, processResource, doNotProcess]);

  useEffect(() => {
    processResource(
      specializations.baseValue,
      (specializationsResource: SpecializationsResource) =>
        processSpecializationsResourceWithPalette(
          specializationsResource,
          CHARTS_APP_SUB_PALETTE,
        ),
      setSpecializationsResource,
    );
  }, [specializations, processResource]);
};

export default useStaticResourcesProcessEffects;
