import ConstructedCvActivitySubactivity from '../ConstructedCvActivitySubactivity';
import CvActivitySubactivitySkillId from '../fields/CvActivitySubactivitySkillId';
import ProcessedSkillsResource from '../../../../../../../../../tools/resources/types/business/skills/processed/ProcessedSkillsResource';
import ConstructedCvSubactivitySkill from '../../fields/skills/constructed/ConstructedCvSubactivitySkill';
import { createSkillFromResource } from '../../fields/skills/CvSubactivitySkill';

const updateConstructedActivitySubactivityWithSkillsResource = (
  constructedSubactivity: ConstructedCvActivitySubactivity,
  skillsResource: ProcessedSkillsResource,
): ConstructedCvActivitySubactivity => {
  if (constructedSubactivity.skills?.length === 0) {
    return constructedSubactivity;
  }

  const skills: ConstructedCvSubactivitySkill[] = [];

  constructedSubactivity.skills?.forEach((constructedSkill) => {
    const foundSkillResource = skillsResource.find(
      (skillResource) =>
        (constructedSkill as CvActivitySubactivitySkillId) === skillResource.id,
    );
    if (foundSkillResource) {
      skills.push(createSkillFromResource(foundSkillResource));
    }
  });

  if (skills.length > 0) {
    constructedSubactivity.skills = skills;
  } else {
    constructedSubactivity.skills = undefined;
  }
  return constructedSubactivity;
};

export default updateConstructedActivitySubactivityWithSkillsResource;
