import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../storage/reducers/rootReducer';
import useRootDispatch from '../../../storage/dispatch/rootDispatch';
import setAppThemeMode from '../../../storage/actions/app/set/theme/setAppThemeMode';

import AppThemeMode, {
  DARK_THEME_MODE,
  LIGHT_THEME_MODE,
} from '../../../types/app/theme/mode/AppThemeMode';

interface UseAppThemeUtils {
  applySystemThemeMode: () => void;
  toggleThemeMode: () => void;
  withThemeMode<T>(light: T, dark: T): T;
}

const useAppThemeUtils = (): UseAppThemeUtils => {
  const dispatch = useRootDispatch();
  const { theme } = useSelector((state: RootState) => state.appReducer);

  const applySystemThemeMode = useCallback(() => {
    let obtainedMode: AppThemeMode = LIGHT_THEME_MODE;

    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      obtainedMode = DARK_THEME_MODE;
    }

    dispatch(setAppThemeMode(obtainedMode));
  }, [dispatch]);

  const toggleThemeMode = useCallback(() => {
    dispatch(
      setAppThemeMode(
        theme.mode === LIGHT_THEME_MODE ? DARK_THEME_MODE : LIGHT_THEME_MODE,
      ),
    );
  }, [dispatch, theme.mode]);

  const withThemeMode = <T>(light: T, dark: T): T => {
    if (theme.mode === LIGHT_THEME_MODE) {
      return light;
    } else {
      return dark;
    }
  };

  return {
    applySystemThemeMode,
    toggleThemeMode,
    withThemeMode,
  };
};

export default useAppThemeUtils;
