import setValue, { SetValue } from '../../../setValue';

import CvActivity from '../../../../../types/business/cv/fields/activity/CvActivity';

import { SET_ACTUAL_CV_ACTIVITIES } from '../../cvActionTypes';

export type SetActualCvActivities = SetValue<
  typeof SET_ACTUAL_CV_ACTIVITIES,
  CvActivity[]
>;

const setActualCvActivities = (cvActivities?: CvActivity[]) =>
  setValue(SET_ACTUAL_CV_ACTIVITIES, cvActivities);

export default setActualCvActivities;
