import ConstructedCvActivity from '../ConstructedCvActivity';
import ProcessedSpecializationsResource from '../../../../../../../tools/resources/types/business/specializations/processed/ProcessedSpecializationsResource';
import ConstructedCvActivityPosition from '../../fields/position/constructed/ConstructedCvActivityPosition';

import updateConstructedActivityPositionWithSpecializationsResource from '../../fields/position/constructed/operations/updateConstructedActivityPositionWithSpecializationsResource';

const updateConstructedActivityWithSpecializationsResource = (
  constructedActivity: ConstructedCvActivity,
  specializationsResource: ProcessedSpecializationsResource,
): ConstructedCvActivity => ({
  ...constructedActivity,
  position: updateConstructedActivityPositionWithSpecializationsResource(
    constructedActivity.position as ConstructedCvActivityPosition,
    specializationsResource,
  ),
});

export default updateConstructedActivityWithSpecializationsResource;
