import HexColorCommon from '../HexColorCommon';
import ThemedHexColorCommon from '../variants/ThemedHexColorCommon';

import isThemed from '../../../guards/isThemed';

const isHexColorCommonThemed = (
  color: HexColorCommon,
): color is ThemedHexColorCommon => isThemed(color);

export default isHexColorCommonThemed;
