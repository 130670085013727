import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../storage/reducers/rootReducer';
import useRootDispatch from '../../../storage/dispatch/rootDispatch';
import setInitialCvUser from '../../../storage/actions/cv/set/user/setInitialCvUser';
import setActualCvUser from '../../../storage/actions/cv/set/user/setActualCvUser';
import setInitialCvActivities from '../../../storage/actions/cv/set/activities/setInitialCvActivities';
import setActualCvActivities from '../../../storage/actions/cv/set/activities/setActualCvActivities';

import useStaticResources from '../../resources/useStaticResources';

import ConstructFlow from '../../../tools/constructed/ConstructFlow';
import ConstructStage from '../../../tools/constructed/ConstructStage';
import removeStagesFromFlow from '../../../tools/constructed/utils/removeStagesFromFlow';
import wasStageRequirementsFulfilled from '../../../tools/constructed/utils/wasStageRequirementsFulfilled';
import isStageExistsInFlow from '../../../tools/constructed/utils/isStageExistsInFlow';

import toCvUser from '../../../types/business/cv/fields/user/constructed/converters/toCvUser';
import toCvActivities from '../../../types/business/cv/fields/activity/constructed/converters/toCvActivities';
import createConstructedUserFromResource from '../../../types/business/cv/fields/user/constructed/operations/createConstructedUserFromResource';
import updateConstructedUserWithPositionsResource from '../../../types/business/cv/fields/user/constructed/operations/updateConstructedUserWithPositionsResource';
import createConstructedActivityFromResource from '../../../types/business/cv/fields/activity/constructed/operations/createConstructedActivityFromResource';
import updateConstructedActivityWithPositionResource from '../../../types/business/cv/fields/activity/constructed/operations/updateConstructedActivityWithPositionResource';
import updateConstructedActivityWithProjectResource from '../../../types/business/cv/fields/activity/constructed/operations/updateConstructedActivityWithProjectResource';
import updateConstructedActivityWithProviderResource from '../../../types/business/cv/fields/activity/constructed/operations/updateConstructedActivityWithProviderResource';
import updateConstructedActivityWithSubactivitiesResource from '../../../types/business/cv/fields/activity/constructed/operations/updateConstructedActivityWithSubactivitiesResource';
import updateConstructedActivityWithSkillsResource from '../../../types/business/cv/fields/activity/constructed/operations/updateConstructedActivityWithSkillsResource';
import updateConstructedUserWithTagsResource from '../../../types/business/cv/fields/user/constructed/operations/updateConstructedUserWithTagsResource';
import updateConstructedActivityWithTagsResource from '../../../types/business/cv/fields/activity/constructed/operations/updateConstructedActivityWithTagsResource';
import updateConstructedUserWithSpecializationsResource from '../../../types/business/cv/fields/user/constructed/operations/updateConstructedUserWithSpecializationsResource';
import updateConstructedActivityWithSpecializationsResource from '../../../types/business/cv/fields/activity/constructed/operations/updateConstructedActivityWithSpecializationsResource';

import { CV_USER_BASE_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/user/constructed/stages/CvUserBaseConstructStage';
import { CV_USER_POSITIONS_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/user/constructed/stages/CvUserPositionsConstructStage';
import { CV_ACTIVITY_BASE_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/activity/constructed/stages/CvActivityBaseConstructStage';
import { CV_ACTIVITY_POSITION_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/activity/constructed/stages/CvActivityPositionConstructStage';
import { CV_ACTIVITY_PROJECT_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/activity/constructed/stages/CvActivityProjectConstructStage';
import { CV_ACTIVITY_PROVIDER_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/activity/constructed/stages/CvActivityProviderConstructStage';
import { CV_ACTIVITY_SUBACTIVITIES_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/activity/constructed/stages/CvActivitySubactivitiesConstructStage';
import { CV_ACTIVITY_SKILLS_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/activity/constructed/stages/CvActivitySkillsConstructStage';
import { CV_USER_TAGS_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/user/constructed/stages/CvUserTagsConstructStage';
import { CV_ACTIVITY_TAGS_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/activity/constructed/stages/CvActivityTagsConstructStage';
import { CV_USER_SPECIALIZATION_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/user/constructed/stages/CvUserSpecializationConstructStage';
import { CV_ACTIVITY_SPECIALIZATION_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/activity/constructed/stages/CvActivitySpecializationConstructStage';

/**
 * @see useCvLoader - Side producer of the useCvConstructingEffects events
 */
const useCvConstructingEffects = () => {
  const dispatch = useRootDispatch();
  const { cvUser, cvActivities } = useSelector(
    (state: RootState) => state.cvReducer,
  );
  const {
    user,
    activities,
    subactivities,
    projects,
    providers,
    positions,
    skills,
    tags,
    specializations,
  } = useStaticResources();

  const onStage = <T, R extends T>(
    stage: ConstructStage<T, R>,
    flow: ConstructFlow<T, R>,
    callback: () => void,
  ) => {
    if (
      isStageExistsInFlow(stage, flow) &&
      wasStageRequirementsFulfilled(stage, flow)
    ) {
      callback();
    }
  };

  // Initial CV user live loading and updating

  useEffect(() => {
    onStage(CV_USER_BASE_CONSTRUCT_STAGE, cvUser.initial.flow, () => {
      if (user) {
        dispatch(
          setInitialCvUser({
            flow: removeStagesFromFlow(
              [CV_USER_BASE_CONSTRUCT_STAGE],
              cvUser.initial.flow,
            ),
            value: createConstructedUserFromResource(user),
          }),
        );
      }
    });
  }, [cvUser.initial.flow, user, dispatch]);

  useEffect(() => {
    onStage(CV_USER_POSITIONS_CONSTRUCT_STAGE, cvUser.initial.flow, () => {
      if (cvUser.initial.value && positions) {
        dispatch(
          setInitialCvUser({
            flow: removeStagesFromFlow(
              [CV_USER_POSITIONS_CONSTRUCT_STAGE],
              cvUser.initial.flow,
            ),
            value: updateConstructedUserWithPositionsResource(
              cvUser.initial.value,
              positions,
            ),
          }),
        );
      }
    });
  }, [cvUser.initial, positions, dispatch]);

  useEffect(() => {
    onStage(CV_USER_SPECIALIZATION_CONSTRUCT_STAGE, cvUser.initial.flow, () => {
      if (cvUser.initial.value && specializations) {
        dispatch(
          setInitialCvUser({
            flow: removeStagesFromFlow(
              [CV_USER_SPECIALIZATION_CONSTRUCT_STAGE],
              cvUser.initial.flow,
            ),
            value: updateConstructedUserWithSpecializationsResource(
              cvUser.initial.value,
              specializations,
            ),
          }),
        );
      }
    });
  }, [cvUser.initial, specializations, dispatch]);

  useEffect(() => {
    onStage(CV_USER_TAGS_CONSTRUCT_STAGE, cvUser.initial.flow, () => {
      if (cvUser.initial.value && tags) {
        dispatch(
          setInitialCvUser({
            flow: removeStagesFromFlow(
              [CV_USER_TAGS_CONSTRUCT_STAGE],
              cvUser.initial.flow,
            ),
            value: updateConstructedUserWithTagsResource(
              cvUser.initial.value,
              tags,
            ),
          }),
        );
      }
    });
  }, [cvUser.initial, tags, dispatch]);

  useEffect(() => {
    dispatch(setActualCvUser(toCvUser(cvUser.initial.value)));
  }, [cvUser.initial.value, dispatch]);

  // Initial CV activities live loading and updating

  useEffect(() => {
    onStage(CV_ACTIVITY_BASE_CONSTRUCT_STAGE, cvActivities.initial.flow, () => {
      if (activities) {
        dispatch(
          setInitialCvActivities({
            flow: removeStagesFromFlow(
              [CV_ACTIVITY_BASE_CONSTRUCT_STAGE],
              cvActivities.initial.flow,
            ),
            value: activities.map((activity) =>
              createConstructedActivityFromResource(activity),
            ),
          }),
        );
      }
    });
  }, [cvActivities.initial.flow, activities, dispatch]);

  useEffect(() => {
    onStage(
      CV_ACTIVITY_POSITION_CONSTRUCT_STAGE,
      cvActivities.initial.flow,
      () => {
        if (cvActivities.initial.value && positions) {
          dispatch(
            setInitialCvActivities({
              flow: removeStagesFromFlow(
                [CV_ACTIVITY_POSITION_CONSTRUCT_STAGE],
                cvActivities.initial.flow,
              ),
              value: cvActivities.initial.value.map((activity) =>
                updateConstructedActivityWithPositionResource(
                  activity,
                  positions,
                ),
              ),
            }),
          );
        }
      },
    );
  }, [cvActivities.initial, positions, dispatch]);

  useEffect(() => {
    onStage(
      CV_ACTIVITY_SPECIALIZATION_CONSTRUCT_STAGE,
      cvActivities.initial.flow,
      () => {
        if (cvActivities.initial.value && specializations) {
          dispatch(
            setInitialCvActivities({
              flow: removeStagesFromFlow(
                [CV_ACTIVITY_SPECIALIZATION_CONSTRUCT_STAGE],
                cvActivities.initial.flow,
              ),
              value: cvActivities.initial.value.map((activity) =>
                updateConstructedActivityWithSpecializationsResource(
                  activity,
                  specializations,
                ),
              ),
            }),
          );
        }
      },
    );
  }, [cvActivities.initial, specializations, dispatch]);

  useEffect(() => {
    onStage(
      CV_ACTIVITY_PROJECT_CONSTRUCT_STAGE,
      cvActivities.initial.flow,
      () => {
        if (cvActivities.initial.value && projects) {
          dispatch(
            setInitialCvActivities({
              flow: removeStagesFromFlow(
                [CV_ACTIVITY_PROJECT_CONSTRUCT_STAGE],
                cvActivities.initial.flow,
              ),
              value: cvActivities.initial.value.map((activity) =>
                updateConstructedActivityWithProjectResource(
                  activity,
                  projects,
                ),
              ),
            }),
          );
        }
      },
    );
  }, [cvActivities.initial, projects, dispatch]);

  useEffect(() => {
    onStage(
      CV_ACTIVITY_PROVIDER_CONSTRUCT_STAGE,
      cvActivities.initial.flow,
      () => {
        if (cvActivities.initial.value && providers) {
          dispatch(
            setInitialCvActivities({
              flow: removeStagesFromFlow(
                [CV_ACTIVITY_PROVIDER_CONSTRUCT_STAGE],
                cvActivities.initial.flow,
              ),
              value: cvActivities.initial.value.map((activity) =>
                updateConstructedActivityWithProviderResource(
                  activity,
                  providers,
                ),
              ),
            }),
          );
        }
      },
    );
  }, [cvActivities.initial, providers, dispatch]);

  useEffect(() => {
    onStage(
      CV_ACTIVITY_SUBACTIVITIES_CONSTRUCT_STAGE,
      cvActivities.initial.flow,
      () => {
        if (cvActivities.initial.value && subactivities) {
          dispatch(
            setInitialCvActivities({
              flow: removeStagesFromFlow(
                [CV_ACTIVITY_SUBACTIVITIES_CONSTRUCT_STAGE],
                cvActivities.initial.flow,
              ),
              value: cvActivities.initial.value.map((activity) =>
                updateConstructedActivityWithSubactivitiesResource(
                  activity,
                  subactivities,
                ),
              ),
            }),
          );
        }
      },
    );
  }, [cvActivities.initial, subactivities, dispatch]);

  useEffect(() => {
    onStage(
      CV_ACTIVITY_SKILLS_CONSTRUCT_STAGE,
      cvActivities.initial.flow,
      () => {
        if (cvActivities.initial.value && skills) {
          dispatch(
            setInitialCvActivities({
              flow: removeStagesFromFlow(
                [CV_ACTIVITY_SKILLS_CONSTRUCT_STAGE],
                cvActivities.initial.flow,
              ),
              value: cvActivities.initial.value.map((activity) =>
                updateConstructedActivityWithSkillsResource(activity, skills),
              ),
            }),
          );
        }
      },
    );
  }, [cvActivities.initial, skills, dispatch]);

  useEffect(() => {
    onStage(CV_ACTIVITY_TAGS_CONSTRUCT_STAGE, cvActivities.initial.flow, () => {
      if (cvActivities.initial.value && tags) {
        dispatch(
          setInitialCvActivities({
            flow: removeStagesFromFlow(
              [CV_ACTIVITY_TAGS_CONSTRUCT_STAGE],
              cvActivities.initial.flow,
            ),
            value: cvActivities.initial.value.map((activity) =>
              updateConstructedActivityWithTagsResource(activity, tags),
            ),
          }),
        );
      }
    });
  }, [cvActivities.initial, tags, dispatch]);

  useEffect(() => {
    dispatch(setActualCvActivities(toCvActivities(cvActivities.initial.value)));
  }, [cvActivities.initial.value, dispatch]);
};

export default useCvConstructingEffects;
