import useI18n from '../internationalization/useI18n';

import NonThemedImageCommon from '../../types/common/media/image/variants/NonThemedImageCommon';
import ThemedImageCommon from '../../types/common/media/image/variants/ThemedImageCommon';

import { ASSETS_LOCALE_NAMESPACE } from '../../tools/internationalization/i18n/localization/namespaces/assets/AssetsLocaleNamespace';
import {
  EMPTY_BOX_ALT_MAPPING,
  GITHUB_LOGO_ALT_MAPPING,
  GITLAB_LOGO_ALT_MAPPING,
  ICV_LOGO_ALT_MAPPING,
  LINKEDIN_LOGO_ALT_MAPPING,
  TELEGRAM_LOGO_ALT_MAPPING,
} from '../../tools/internationalization/i18n/localization/namespaces/assets/AssetsLocaleNamespaceMappings';
import {
  CIRCULAR_IMAGE_SHAPE,
  RECTANGULAR_IMAGE_SHAPE,
  ROUNDED_IMAGE_SHAPE,
} from '../../types/common/media/image/fields/shape/ImageCommonShape';

import IcvLogo from '../../assets/media/svg/icv-logo.svg';
import EmptyBoxLogo from '../../assets/media/svg/empty-box-logo.svg';
import EmptyBoxDarkLogo from '../../assets/media/svg/empty-box-logo-dark.svg';
import GitLabLogo from '../../assets/media/svg/gitlab-logo.svg';
import GitHubLogo from '../../assets/media/svg/github-logo.svg';
import GitHubLogoDark from '../../assets/media/svg/github-logo-dark.svg';
import LinkedInLogo from '../../assets/media/svg/linkedin-logo.svg';
import TelegramLogo from '../../assets/media/svg/telegram_logo.svg';

type RequiredThemedImageCommon = Required<ThemedImageCommon>;
type RequiredNonThemedImageCommon = Required<NonThemedImageCommon>;

interface UseAssetsResources {
  icvLogo: RequiredNonThemedImageCommon;
  emptyBoxLogo: RequiredThemedImageCommon;
  gitLabLogo: RequiredNonThemedImageCommon;
  gitHubLogo: RequiredThemedImageCommon;
  linkedInLogo: RequiredNonThemedImageCommon;
  telegramLogo: RequiredNonThemedImageCommon;
}

const useAssetsResources = (): UseAssetsResources => {
  const { t: tA } = useI18n(ASSETS_LOCALE_NAMESPACE);

  const icvLogo: RequiredNonThemedImageCommon = {
    src: IcvLogo,
    alt: tA(ICV_LOGO_ALT_MAPPING),
    shape: ROUNDED_IMAGE_SHAPE,
  };

  const emptyBoxLogo: RequiredThemedImageCommon = {
    src: {
      light: EmptyBoxDarkLogo,
      dark: EmptyBoxLogo,
    },
    alt: tA(EMPTY_BOX_ALT_MAPPING),
    shape: RECTANGULAR_IMAGE_SHAPE,
  };

  const gitLabLogo: RequiredNonThemedImageCommon = {
    src: GitLabLogo,
    alt: tA(GITLAB_LOGO_ALT_MAPPING),
    shape: RECTANGULAR_IMAGE_SHAPE,
  };

  const gitHubLogo: RequiredThemedImageCommon = {
    src: {
      light: GitHubLogoDark,
      dark: GitHubLogo,
    },
    alt: tA(GITHUB_LOGO_ALT_MAPPING),
    shape: CIRCULAR_IMAGE_SHAPE,
  };

  const linkedInLogo: RequiredNonThemedImageCommon = {
    src: LinkedInLogo,
    alt: tA(LINKEDIN_LOGO_ALT_MAPPING),
    shape: ROUNDED_IMAGE_SHAPE,
  };

  const telegramLogo: RequiredNonThemedImageCommon = {
    src: TelegramLogo,
    alt: tA(TELEGRAM_LOGO_ALT_MAPPING),
    shape: CIRCULAR_IMAGE_SHAPE,
  };

  return {
    icvLogo,
    emptyBoxLogo,
    gitLabLogo,
    gitHubLogo,
    linkedInLogo,
    telegramLogo,
  };
};

export default useAssetsResources;
