import CvActivity from '../../../../types/business/cv/fields/activity/CvActivity';
import Experience from '../../../../types/business/experience/Experience';
import SkillId from '../../../../types/business/skill/SkillId';
import CvActivitySubactivity from '../../../../types/business/cv/fields/activity/fields/subactivities/CvActivitySubactivity';
import { NO_SKILL_EXPERIENCE } from '../../../../types/business/skill/SkillExperience';

import deepClone from '../../../utils/deepClone';
import compareDates from '../../../timedate/date/utils/compareDates';
import calculateDuration from '../../../timedate/duration/utils/calculateDuration';
import intersectPeriods from '../../../timedate/period/utils/intersectPeriods';

const trimSubactivitiesPeriods = (activities: CvActivity[]): CvActivity[] =>
  activities.map((activity) => {
    if (activity.subactivities) {
      activity.subactivities = activity.subactivities.map((subactivity) => {
        if (compareDates(subactivity.period.start, activity.period.start) < 0) {
          subactivity.period.start = activity.period.start;
        }
        if (compareDates(subactivity.period.end, activity.period.end) > 0) {
          subactivity.period.end = activity.period.end;
        }
        return subactivity;
      });
    }
    return activity;
  });

const collectUniqueSkills = (
  activities: CvActivity[],
): Map<SkillId, Experience> => {
  const uniqueSkills = new Map<SkillId, Experience>();

  activities.forEach((activity) => {
    if (activity.subactivities) {
      activity.subactivities.forEach((subactivity) => {
        if (subactivity.skills) {
          subactivity.skills.forEach((skill) => {
            uniqueSkills.set(skill.id, {
              id: skill.id,
              name: skill.name,
              experience: NO_SKILL_EXPERIENCE,
              tags: skill.tags,
              avatar: skill.avatar,
              color: skill.color,
            });
          });
        }
      });
    }
  });

  return uniqueSkills;
};

const collectSubactivities = (
  activities: CvActivity[],
): CvActivitySubactivity[] => {
  const subactivities: CvActivitySubactivity[] = [];

  activities.forEach((activity) => {
    if (activity.subactivities) {
      subactivities.push(...activity.subactivities);
    }
  });

  return subactivities;
};

const calculateExperienceOfUniqueSkills = (
  uniqueSkills: Map<SkillId, Experience>,
  subactivities: CvActivitySubactivity[],
): Experience[] => {
  const experienceOfUniqueSkills: Experience[] = [];

  uniqueSkills.forEach((skill) => {
    const subactivitiesWithSkill = subactivities.filter((subactivity) =>
      subactivity.skills
        ? subactivity.skills.find(
            (subactivitySkill) => subactivitySkill.id === skill.id,
          )
        : undefined,
    );
    experienceOfUniqueSkills.push({
      ...skill,
      experience: calculateDuration(
        intersectPeriods(
          subactivitiesWithSkill.map(
            (activityWithSkill) => activityWithSkill.period,
          ),
        ),
      ),
    });
  });

  return experienceOfUniqueSkills;
};

const calculateSkillsExperience = (activities: CvActivity[]): Experience[] => {
  const activitiesClone = deepClone(activities);
  const trimmedActivities = trimSubactivitiesPeriods(activitiesClone);
  const uniqueSkills = collectUniqueSkills(trimmedActivities);
  const subactivities = collectSubactivities(trimmedActivities);
  return calculateExperienceOfUniqueSkills(uniqueSkills, subactivities);
};

export default calculateSkillsExperience;
