import React, { ReactElement, useEffect, useMemo } from 'react';

import { Badge, Construction, Work } from '@mui/icons-material';
import { Stack, useScrollTrigger } from '@mui/material';

import useCv from '../../../../../hooks/business/cv/useCv';
import useAppTheme from '../../../../../hooks/layout/theme/useAppTheme';
import useElementStickiness from '../../../../../hooks/layout/elements/useElementStickiness';
import useWindowActions from '../../../../../hooks/layout/window/useWindowActions';
import useI18n from '../../../../../hooks/internationalization/useI18n';
import useInternalNavigation from '../../../../../hooks/navigation/useInternalNavigation';
import useLocation from '../../../../../hooks/navigation/useLocation';
import useAppTabsScroll from '../../../../../hooks/layout/tabs/useAppTabsScroll';

import UrlPath from '../../../../../tools/web/url/paths/UrlPath';
import AppTabKey, {
  ACTIVITIES_APP_TAB_KEY,
  POSITIONS_APP_TAB_KEY,
  SKILLS_APP_TAB_KEY,
} from '../../../../../types/app/tabs/key/AppTabKey';

import { ComponentProps } from '../../../ComponentProps';
import DefaultBackgroundMolecule from '../../../molecules/background/default/DefaultBackgroundMolecule';
import FilterButtonMolecule from '../../../molecules/buttons/filter/FilterButtonMolecule';
import TabbedNavigationMolecule from '../../../molecules/navigation/tabbed/TabbedNavigationMolecule';
import LoadingBackgroundMolecule from '../../../molecules/background/loading/LoadingBackgroundMolecule';

import { ORGANISMS_LOCALE_NAMESPACE } from '../../../../../tools/internationalization/i18n/localization/namespaces/organisms/OrganismsLocaleNamespace';
import {
  CV_ACTIVITIES_NAME_MAPPING,
  CV_POSITIONS_NAME_MAPPING,
  CV_SKILLS_NAME_MAPPING,
} from '../../../../../tools/internationalization/i18n/localization/namespaces/organisms/OrganismsLocaleNamespaceMappings';

type Tab = {
  key: AppTabKey;
  path: UrlPath;
  name: string;
  icon?: ReactElement;
};

type CvNavigationOrganismProps = ComponentProps;

const CvNavigationOrganism: React.FC<CvNavigationOrganismProps> = ({
  className = 'cv-navigation-organism',
}) => {
  const scrollTrigger = useScrollTrigger({ threshold: 600 });
  const { appTheme } = useAppTheme();
  const { elementRef, isSticky } = useElementStickiness({
    threshold: 1,
  });
  const { scrollToTopSmoothly } = useWindowActions();

  const { t } = useI18n(ORGANISMS_LOCALE_NAMESPACE);
  const { cvActivities } = useCv();

  const { toActivities, toSkills, toPositions, navToPath } =
    useInternalNavigation();
  const { path } = useLocation();

  const { saveScroll, restoreScroll } = useAppTabsScroll();
  const [currentTabKey, setCurrentTabKey] = React.useState<AppTabKey>(
    ACTIVITIES_APP_TAB_KEY,
  );

  const TABS: Tab[] = useMemo(
    () => [
      {
        key: ACTIVITIES_APP_TAB_KEY,
        path: toActivities.path,
        icon: <Work />,
        name: t(CV_ACTIVITIES_NAME_MAPPING),
      },
      {
        key: SKILLS_APP_TAB_KEY,
        path: toSkills.path,
        icon: <Construction />,
        name: t(CV_SKILLS_NAME_MAPPING),
      },
      {
        key: POSITIONS_APP_TAB_KEY,
        path: toPositions.path,
        icon: <Badge />,
        name: t(CV_POSITIONS_NAME_MAPPING),
      },
    ],
    [toActivities.path, toPositions.path, toSkills.path, t],
  );

  useEffect(() => {
    const newTab = TABS.find((tab) => tab.path === path);
    if (newTab && newTab.key !== currentTabKey) {
      restoreScroll(newTab.key);
      setCurrentTabKey(newTab.key);
    }
  }, [TABS, path, currentTabKey, setCurrentTabKey, restoreScroll]);

  const onTabChange = (newTabKey: AppTabKey) => {
    const newTab = TABS.find((tab) => tab.key === newTabKey);
    if (newTab) {
      saveScroll(currentTabKey);
      navToPath(newTab.path);
    }
  };

  const onTabClick = (key: AppTabKey) => {
    if (key === currentTabKey) {
      scrollToTopSmoothly();
    }
  };

  return cvActivities ? (
    <DefaultBackgroundMolecule
      className={`cv-navigation__background${isSticky ? ' sticky' : ''}`}
      ref={elementRef}
      sx={{
        position: 'sticky',
        padding: '16px 0 0 0',
        marginBottom: '16px',
        borderRadius: '12px',
        zIndex: appTheme.zIndex.appBar,
        transition: 'top 0.2s ease',
        top: {
          xs: scrollTrigger ? '-70px' : '-8px',
          sm: '-8px',
        },
      }}>
      <Stack
        className={`${className} cv-navigation`}
        direction='row'
        alignItems='center'
        spacing={2}
        borderRadius='12px'
        boxShadow={isSticky ? appTheme.shadows[2] : appTheme.shadows[0]}>
        <FilterButtonMolecule className='cv-navigation-organism__filter-button' />
        <TabbedNavigationMolecule
          className='cv-navigation__tabbed-navigation'
          currentTab={currentTabKey}
          tabs={TABS}
          onChange={onTabChange}
          onTabClick={onTabClick}
        />
      </Stack>
    </DefaultBackgroundMolecule>
  ) : (
    <LoadingBackgroundMolecule
      className='cv-navigation__loading'
      height='80px'
      sx={{ marginY: '16px' }}
    />
  );
};

export default CvNavigationOrganism;
