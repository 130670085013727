import Experience from '../../../../types/business/experience/Experience';

import compareDurations from '../../../timedate/duration/utils/compareDurations';

const sortSkillsExperience = (experience: Experience[]) =>
  experience
    .sort((a, b) => compareDurations(a.experience, b.experience))
    .reverse();

export default sortSkillsExperience;
