import { useSelector } from 'react-redux';

import { RootState } from '../../../storage/reducers/rootReducer';

import CvUser from '../../../types/business/cv/fields/user/CvUser';
import CvActivity from '../../../types/business/cv/fields/activity/CvActivity';

interface UseCv {
  cvUser?: CvUser;
  cvActivities?: CvActivity[];
}

const useCv = (): UseCv => {
  const { cvUser, cvActivities } = useSelector(
    (state: RootState) => state.cvReducer,
  );

  return {
    cvUser: cvUser.actual,
    cvActivities: cvActivities.actual,
  };
};

export default useCv;
