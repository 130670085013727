import setValue, { SetValue } from '../../setValue';

import ActivitiesResource from '../../../../tools/resources/types/business/activities/ActivitiesResource';

import { LOAD_ACTIVITIES_RESOURCE_SET } from '../resourceActionTypes';

export type SetActivitiesResource = SetValue<
  typeof LOAD_ACTIVITIES_RESOURCE_SET,
  ActivitiesResource
>;

const setActivitiesResource = (resource: ActivitiesResource) =>
  setValue(LOAD_ACTIVITIES_RESOURCE_SET, resource);

export default setActivitiesResource;
