import Constructed from '../../tools/constructed/Constructed';
import CvUser from '../../types/business/cv/fields/user/CvUser';
import ConstructedCvUser from '../../types/business/cv/fields/user/constructed/ConstructedCvUser';
import CvActivity from '../../types/business/cv/fields/activity/CvActivity';
import ConstructedCvActivity from '../../types/business/cv/fields/activity/constructed/ConstructedCvActivity';
import CvUserConstructFlow, {
  CV_USER_CONSTRUCT_FLOW,
} from '../../types/business/cv/fields/user/constructed/CvUserConstructFlow';
import CvActivitiesConstructFlow, {
  CV_ACTIVITIES_CONSTRUCT_FLOW,
} from '../../types/business/cv/fields/activity/constructed/CvActivitiesConstructFlow';
import FilterData from '../../types/business/filter/data/FilterData';
import Filter from '../../types/business/filter/common/Filter';
import {
  SET_ACTUAL_CV_ACTIVITIES,
  SET_ACTUAL_CV_USER,
  SET_APPLIED_FILTERS,
  SET_AVAILABLE_FILTERS,
  SET_INITIAL_CV_ACTIVITIES,
  SET_INITIAL_CV_USER,
} from '../actions/cv/cvActionTypes';
import { SetInitialCvUser } from '../actions/cv/set/user/setInitialCvUser';
import { SetActualCvUser } from '../actions/cv/set/user/setActualCvUser';
import { SetInitialCvActivities } from '../actions/cv/set/activities/setInitialCvActivities';
import { SetActualCvActivities } from '../actions/cv/set/activities/setActualCvActivities';
import { SetAppliedFilters } from '../actions/cv/set/filters/setAppliedFilters';
import { SetAvailableFilters } from '../actions/cv/set/filters/setAvailableFilters';
import LoadableValue from '../modifiers/LoadableValue';

interface CvStore {
  cvUser: {
    initial: Constructed<CvUserConstructFlow, ConstructedCvUser | undefined>;
    actual?: CvUser;
  };
  cvActivities: {
    initial: Constructed<
      CvActivitiesConstructFlow,
      ConstructedCvActivity[] | undefined
    >;
    actual?: CvActivity[];
  };
  // TODO: Transform to Stash with HashCode-Filter[] entities to enable caching
  filters: {
    available: LoadableValue<FilterData[]>;
    applied: LoadableValue<Filter[]>;
  };
}

const initialState: CvStore = {
  cvUser: {
    initial: { flow: CV_USER_CONSTRUCT_FLOW, value: undefined },
    actual: undefined,
  },
  cvActivities: {
    initial: {
      flow: CV_ACTIVITIES_CONSTRUCT_FLOW,
      value: undefined,
    },
    actual: undefined,
  },
  filters: {
    available: {
      loading: true,
      value: [],
    },
    applied: {
      loading: false,
      value: [],
    },
  },
};

type CvReducerTypes =
  | SetInitialCvUser
  | SetActualCvUser
  | SetInitialCvActivities
  | SetActualCvActivities
  | SetAvailableFilters
  | SetAppliedFilters;

export const cvReducer = (
  state = initialState,
  action: CvReducerTypes,
): CvStore => {
  switch (action.type) {
    case SET_INITIAL_CV_USER: {
      return {
        ...state,
        cvUser: {
          ...state.cvUser,
          initial: action.payload,
        },
      };
    }
    case SET_ACTUAL_CV_USER: {
      return {
        ...state,
        cvUser: {
          ...state.cvUser,
          actual: action.payload,
        },
      };
    }
    case SET_INITIAL_CV_ACTIVITIES: {
      return {
        ...state,
        cvActivities: {
          ...state.cvActivities,
          initial: action.payload,
        },
      };
    }
    case SET_ACTUAL_CV_ACTIVITIES: {
      return {
        ...state,
        cvActivities: {
          ...state.cvActivities,
          actual: action.payload,
        },
      };
    }
    case SET_AVAILABLE_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          available: action.payload,
        },
      };
    }
    case SET_APPLIED_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          applied: action.payload,
        },
      };
    }
    default:
      return state;
  }
};
