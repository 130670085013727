import ConstructedCvActivity from '../ConstructedCvActivity';
import ProcessedSkillsResource from '../../../../../../../tools/resources/types/business/skills/processed/ProcessedSkillsResource';
import ConstructedCvActivitySubactivity from '../../fields/subactivities/constructed/ConstructedCvActivitySubactivity';
import updateConstructedActivitySubactivityWithSkillsResource from '../../fields/subactivities/constructed/operations/updateConstructedActivitySubactivityWithSkillsResource';

const updateConstructedActivityWithSkillsResource = (
  constructedActivity: ConstructedCvActivity,
  skillsResource: ProcessedSkillsResource,
): ConstructedCvActivity => {
  if (constructedActivity.subactivities?.length === 0) {
    return constructedActivity;
  }

  const subactivities: ConstructedCvActivitySubactivity[] = [];

  constructedActivity.subactivities?.forEach((constructedSubactivity) => {
    subactivities.push(
      updateConstructedActivitySubactivityWithSkillsResource(
        constructedSubactivity as ConstructedCvActivitySubactivity,
        skillsResource,
      ),
    );
  });

  if (subactivities.length > 0) {
    constructedActivity.subactivities = subactivities;
  } else {
    constructedActivity.subactivities = undefined;
  }

  return constructedActivity;
};

export default updateConstructedActivityWithSkillsResource;
