import React from 'react';

import { Box, Stack } from '@mui/material';

import PositionId from '../../../../../types/business/position/PositionId';
import PositionName from '../../../../../types/business/position/PositionName';
import SpecializationId from '../../../../../types/business/specialization/SpecializationId';
import SpecializationName from '../../../../../types/business/specialization/SpecializationName';
import { PositionSpecializationExperience } from '../../../../../tools/business/cv/calculate/calculatePositionSpecializationsExperience';
import Duration from '../../../../../tools/timedate/duration/Duration';
import HexColorCommon from '../../../../../types/common/media/color/hex/HexColorCommon';

import { ComponentProps } from '../../../ComponentProps';
import ExperienceChipMolecule from '../../chips/experience/ExperienceChipMolecule';

type ExperienceListMoleculeProps = ComponentProps & {
  positionSpecializationsExperience: PositionSpecializationExperience[];
};

const ExperienceListMolecule: React.FC<ExperienceListMoleculeProps> = ({
  className,
  positionSpecializationsExperience,
}) => {
  const renderChip = (
    id: SpecializationId | PositionId,
    label: SpecializationName | PositionName,
    experience: Duration,
    color: HexColorCommon,
  ) => (
    <Box
      className='experience-list__item'
      component='li'
      width='fit-content'
      height='fit-content'
      key={id}
      sx={{ listStyle: 'none' }}>
      <ExperienceChipMolecule
        id={id}
        label={label}
        experience={experience}
        color={color}
      />
    </Box>
  );

  const renderSpecializationChips = (
    experiences: PositionSpecializationExperience[],
  ) =>
    experiences.map((experience) =>
      renderChip(
        experience.specialization.id,
        experience.specialization.name,
        experience.experience,
        experience.specialization.color,
      ),
    );

  const renderPositionChips = (
    experiences: PositionSpecializationExperience[],
  ) =>
    experiences.flatMap((experience) =>
      experience.positionExperiences.map((positionExperience) =>
        renderChip(
          positionExperience.position.id,
          positionExperience.position.name,
          positionExperience.experience,
          positionExperience.position.color,
        ),
      ),
    );

  const renderChips = (experiences: PositionSpecializationExperience[]) => [
    ...renderSpecializationChips(experiences),
    ...renderPositionChips(experiences),
  ];

  return (
    <Stack
      className={`${className} experience-list`}
      component='ul'
      width='100%'
      height='fit-content'
      padding={0}
      margin={0}
      direction='row'
      useFlexGap
      flexWrap='wrap'>
      {renderChips(positionSpecializationsExperience)}
    </Stack>
  );
};

export default ExperienceListMolecule;
