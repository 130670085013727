import Specialization from '../../../../../../../specialization/Specialization';
import ProcessedSpecializationResource from '../../../../../../../../../tools/resources/types/business/specializations/processed/ProcessedSpecializationResource';

type CvUserPositionSpecialization = Specialization;

export const createCvUserPositionSpecializationFromResource = (
  specializationResource: ProcessedSpecializationResource,
): CvUserPositionSpecialization => ({
  id: specializationResource.id,
  name: specializationResource.name,
  color: specializationResource.color,
});

export default CvUserPositionSpecialization;
