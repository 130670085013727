import ConstructedCvUserPosition from '../ConstructedCvUserPosition';
import CvUserPositionSpecializationId from '../fields/CvUserPositionSpecializationId';
import ProcessedSpecializationsResource from '../../../../../../../../../tools/resources/types/business/specializations/processed/ProcessedSpecializationsResource';

import { createCvUserPositionSpecializationFromResource } from '../../fields/specialization/CvUserPositionSpecialization';

const updateConstructedUserPositionWithSpecializationsResource = (
  constructedPosition: ConstructedCvUserPosition,
  specializationsResource: ProcessedSpecializationsResource,
): ConstructedCvUserPosition => {
  const foundSpecializationResource = specializationsResource.find(
    (specialization) =>
      specialization.id ===
      (constructedPosition.specialization as CvUserPositionSpecializationId),
  );

  if (!foundSpecializationResource) {
    return constructedPosition;
  }

  return {
    ...constructedPosition,
    specialization: createCvUserPositionSpecializationFromResource(
      foundSpecializationResource,
    ),
  };
};

export default updateConstructedUserPositionWithSpecializationsResource;
