import SkillsResource from '../../../types/business/skills/SkillsResource';
import ProcessedSkillsResource from '../../../types/business/skills/processed/ProcessedSkillsResource';
import ThemedHexPaletteCommon from '../../../../../types/common/media/palette/hex/variants/ThemedHexPaletteCommon';

import ResourceProcessResult from '../../types/result/ResourceProcessResult';

import processResourceWithPalette from '../../common/processResourceWithPalette';

const processSkillsResourceWithPalette = (
  skillsResource: SkillsResource,
  palette: ThemedHexPaletteCommon,
): ResourceProcessResult<ProcessedSkillsResource> => ({
  success: true,
  resource: processResourceWithPalette(skillsResource, palette),
});

export default processSkillsResourceWithPalette;
