import React from 'react';

import { PieChart, PieChartProps } from '@mui/x-charts';

const PIE_CHART_LEGEND_ROW_DIRECTION = 'row';
const PIE_CHART_LEGEND_COLUMN_DIRECTION = 'column';
const PIE_CHART_LEGEND_ANY_ALIGNMENT_CENTER = 'center';
const PIE_CHART_LEGEND_ANY_ALIGNMENT_LIBRARY_CENTER = 'middle';
const PIE_CHART_LEGEND_VERTICAL_ALIGNMENT_TOP = 'top';
const PIE_CHART_LEGEND_VERTICAL_ALIGNMENT_BOTTOM = 'bottom';
const PIE_CHART_LEGEND_HORIZONTAL_ALIGNMENT_LEFT = 'left';
const PIE_CHART_LEGEND_HORIZONTAL_ALIGNMENT_RIGHT = 'right';

type PieChartAtomProps = PieChartProps & {
  legendHidden?: boolean;
  legendDirection?:
    | typeof PIE_CHART_LEGEND_ROW_DIRECTION
    | typeof PIE_CHART_LEGEND_COLUMN_DIRECTION;
  legendVerticalAlignment?:
    | typeof PIE_CHART_LEGEND_VERTICAL_ALIGNMENT_TOP
    | typeof PIE_CHART_LEGEND_ANY_ALIGNMENT_CENTER
    | typeof PIE_CHART_LEGEND_VERTICAL_ALIGNMENT_BOTTOM;
  legendHorizontalAlignment?:
    | typeof PIE_CHART_LEGEND_HORIZONTAL_ALIGNMENT_LEFT
    | typeof PIE_CHART_LEGEND_ANY_ALIGNMENT_CENTER
    | typeof PIE_CHART_LEGEND_HORIZONTAL_ALIGNMENT_RIGHT;
};

const PieChartAtom: React.FC<PieChartAtomProps> = ({
  className,
  series,
  width,
  height,
  skipAnimation = true,
  legendHidden = false,
  legendDirection = PIE_CHART_LEGEND_ROW_DIRECTION,
  legendVerticalAlignment = PIE_CHART_LEGEND_VERTICAL_ALIGNMENT_BOTTOM,
  legendHorizontalAlignment = PIE_CHART_LEGEND_ANY_ALIGNMENT_CENTER,
  ...props
}) => {
  const customSx = {
    '&&': {
      touchAction: 'auto',
    },
  };

  return (
    <PieChart
      className={className}
      series={series}
      width={width}
      height={height}
      skipAnimation={skipAnimation}
      slotProps={{
        legend: {
          hidden: legendHidden,
          direction: legendDirection,
          position: {
            vertical:
              legendVerticalAlignment === PIE_CHART_LEGEND_ANY_ALIGNMENT_CENTER
                ? PIE_CHART_LEGEND_ANY_ALIGNMENT_LIBRARY_CENTER
                : legendVerticalAlignment,
            horizontal:
              legendHorizontalAlignment ===
              PIE_CHART_LEGEND_ANY_ALIGNMENT_CENTER
                ? PIE_CHART_LEGEND_ANY_ALIGNMENT_LIBRARY_CENTER
                : legendHorizontalAlignment,
          },
        },
      }}
      sx={customSx}
      {...props}
    />
  );
};

export default PieChartAtom;
