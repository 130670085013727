import React from 'react';
import { Outlet } from 'react-router-dom';

import useCvLoaderEffects from '../../../hooks/business/cv/useCvLoaderEffects';
import useStaticResourcesLoaderEffects from '../../../hooks/resources/useStaticResourcesLoaderEffects';
import useStaticResourcesProcessEffects from '../../../hooks/resources/useStaticResourcesProcessEffects';
import useCvFilteringEffects from '../../../hooks/business/cv/useCvFilteringEffects';
import AppProps from '../AppProps';
import useCvConstructingEffects from '../../../hooks/business/cv/useCvConstructingEffects';

const AppEffects: React.FC<AppProps> = ({ children = <Outlet /> }) => {
  useCvLoaderEffects();
  useCvConstructingEffects();
  useStaticResourcesLoaderEffects();
  useStaticResourcesProcessEffects();
  useCvFilteringEffects();

  return <>{children}</>;
};

export default AppEffects;
