import Url from '../../tools/web/url/Url';

import useAppThemeUtils from '../layout/theme/useAppThemeUtils';
import buildResourceUrl from '../../tools/resources/utils/buildResourceUrl';
import isImageSourceThemed from '../../types/common/media/image/fields/source/guards/isImageSourceThemed';
import ImageCommonSource from '../../types/common/media/image/fields/source/ImageCommonSource';

interface UseDynamicResources {
  buildUrlFromImageResourceSource(src: ImageCommonSource): Url;
}

const useDynamicResources = (): UseDynamicResources => {
  const { withThemeMode } = useAppThemeUtils();

  const buildUrlFromImageResourceSource = (src: ImageCommonSource) =>
    buildResourceUrl({
      path: isImageSourceThemed(src) ? withThemeMode(src.light, src.dark) : src,
    });

  return {
    buildUrlFromImageResourceSource,
  };
};

export default useDynamicResources;
