import React from 'react';

import { Avatar, AvatarProps } from '@mui/material';

import ImageCommonShape, {
  CIRCULAR_IMAGE_SHAPE,
  RECTANGULAR_IMAGE_SHAPE,
  ROUNDED_IMAGE_SHAPE,
} from '../../../../types/common/media/image/fields/shape/ImageCommonShape';

type AvatarAtomProps = AvatarProps & {
  shape?: ImageCommonShape;
  placeholder?: boolean;
};

const AvatarAtom: React.FC<AvatarAtomProps> = ({
  className,
  src,
  alt,
  placeholder = false,
  shape,
  sx,
  children,
}) => {
  const determineVariantFromShape = () => {
    switch (shape) {
      case ROUNDED_IMAGE_SHAPE:
        return 'rounded';
      case RECTANGULAR_IMAGE_SHAPE:
        return 'square';
      case CIRCULAR_IMAGE_SHAPE:
      default:
        return 'circular';
    }
  };

  const renderChildren = () => {
    if (placeholder) {
      return undefined;
    }
    if (children) {
      return children;
    }
    return alt?.substring(0, 1).toUpperCase();
  };

  return (
    <Avatar
      className={className}
      variant={determineVariantFromShape()}
      src={src}
      alt={alt}
      sx={sx}>
      {renderChildren()}
    </Avatar>
  );
};
export default AvatarAtom;
