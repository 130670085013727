import React from 'react';

import { Box } from '@mui/material';
import { DarkMode, LightMode } from '@mui/icons-material';

import useI18n from '../../../../../hooks/internationalization/useI18n';
import useAppThemeUtils from '../../../../../hooks/layout/theme/useAppThemeUtils';

import { ComponentProps } from '../../../ComponentProps';
import TooltipAtom from '../../../atoms/tooltips/TooltipAtom';
import IconToggleAtom from '../../../atoms/toggles/icon/IconToggleAtom';

import { MOLECULES_LOCALE_NAMESPACE } from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespace';
import { TOGGLE_THEME_TOOLTIP_MAPPING } from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespaceMappings';

type ThemeToggleMoleculeProps = ComponentProps;

const ThemeModeToggleMolecule: React.FC<ThemeToggleMoleculeProps> = ({
  className,
}) => {
  const { t } = useI18n(MOLECULES_LOCALE_NAMESPACE);
  const { withThemeMode, toggleThemeMode } = useAppThemeUtils();

  return (
    <TooltipAtom
      className='theme-button__tooltip'
      title={t(TOGGLE_THEME_TOOLTIP_MAPPING)}
      arrow>
      <Box className='theme-button__tooltip-wrapper'>
        <IconToggleAtom
          className={`${className} list-chart-switch`}
          checked={withThemeMode(false, true)}
          icon={<LightMode />}
          checkedIcon={<DarkMode />}
          onChange={() => toggleThemeMode()}
        />
      </Box>
    </TooltipAtom>
  );
};

export default ThemeModeToggleMolecule;
