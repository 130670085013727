import CvActivity from '../../../../types/business/cv/fields/activity/CvActivity';
import ActivityType from '../../../../types/business/activity/ActivityType';
import FilterData from '../../../../types/business/filter/data/FilterData';
import {
  ACTIVITY_POSITION_FILTER,
  POSITION_SPECIALIZATION_FILTER,
  ACTIVITY_TYPE_FILTER,
} from '../../../../types/business/filter/data/prototype/fields/FilterType';

const generateActivityTypeFilterData = (
  activities: CvActivity[],
): FilterData[] => {
  const initialFilters: Map<ActivityType, FilterData> = new Map();

  activities.forEach((activity) => {
    if (!initialFilters.has(activity.type)) {
      initialFilters.set(activity.type, {
        type: ACTIVITY_TYPE_FILTER,
        data: activity.type,
      });
    }
  });

  return Array.from(initialFilters.values());
};

const generateActivityPositionFilterData = (
  activities: CvActivity[],
): FilterData[] => {
  const initialFilters: Map<string, FilterData> = new Map();

  activities.forEach((activity) => {
    if (activity.position && !initialFilters.has(activity.position.id)) {
      initialFilters.set(activity.position.id, {
        type: ACTIVITY_POSITION_FILTER,
        data: {
          id: activity.position.id,
          name: activity.position.name,
          color: activity.position.color,
          specialization: activity.position.specialization,
        },
      });
    }
  });

  return Array.from(initialFilters.values());
};

const generatePositionSpecializationFilterData = (
  activities: CvActivity[],
): FilterData[] => {
  const initialFilters: Map<string, FilterData> = new Map();

  activities.forEach((activity) => {
    if (
      activity.position &&
      activity.position.specialization &&
      !initialFilters.has(activity.position.specialization.id)
    ) {
      initialFilters.set(activity.position.specialization.id, {
        type: POSITION_SPECIALIZATION_FILTER,
        data: {
          id: activity.position.specialization.id,
          name: activity.position.specialization.name,
          color: activity.position.specialization.color,
        },
      });
    }
  });

  return Array.from(initialFilters.values());
};

const generateAvailableFilterData = (
  activities: CvActivity[],
): FilterData[] => {
  const availableFilterData: FilterData[] = [];

  availableFilterData.push(...generateActivityTypeFilterData(activities));
  availableFilterData.push(...generateActivityPositionFilterData(activities));
  availableFilterData.push(
    ...generatePositionSpecializationFilterData(activities),
  );

  return availableFilterData;
};

export default generateAvailableFilterData;
