import PositionId from '../../../../types/business/position/PositionId';
import Position from '../../../../types/business/position/Position';
import CvActivity from '../../../../types/business/cv/fields/activity/CvActivity';
import Duration from '../../../timedate/duration/Duration';
import Period from '../../../timedate/period/Period';
import MultiPeriod from '../../../timedate/period/MultiPeriod';

import deepClone from '../../../utils/deepClone';
import intersectPeriods from '../../../timedate/period/utils/intersectPeriods';
import calculateDuration from '../../../timedate/duration/utils/calculateDuration';
import compareDates from '../../../timedate/date/utils/compareDates';

export type PositionExperience = {
  position: Position;
  experience: Duration;
};

type PositionPeriod = {
  position: Position;
  period: Period;
};

const trimSubactivitiesPeriods = (activities: CvActivity[]): CvActivity[] =>
  activities.map((activity) => {
    if (activity.subactivities) {
      activity.subactivities = activity.subactivities.map((subactivity) => {
        if (compareDates(subactivity.period.start, activity.period.start) < 0) {
          subactivity.period.start = activity.period.start;
        }
        if (compareDates(subactivity.period.end, activity.period.end) > 0) {
          subactivity.period.end = activity.period.end;
        }
        return subactivity;
      });
    }
    return activity;
  });

const groupSubactivityPeriodsByUniquePositions = (
  activities: CvActivity[],
): Map<PositionId, PositionPeriod> => {
  const positionPeriods = new Map<PositionId, PositionPeriod>();

  activities.forEach((activity) => {
    if (activity.position && activity.subactivities) {
      const periodsOfSubactivities: MultiPeriod = activity.subactivities.map(
        (subactivity) => subactivity.period,
      );

      if (positionPeriods.has(activity.position.id)) {
        positionPeriods.set(activity.position.id, {
          position: activity.position,
          period: [
            ...((positionPeriods.get(activity.position.id) as PositionPeriod)
              .period as MultiPeriod),
            ...periodsOfSubactivities,
          ] as MultiPeriod,
        });
      } else {
        positionPeriods.set(activity.position.id, {
          position: activity.position,
          period: periodsOfSubactivities,
        });
      }
    }
  });

  return positionPeriods;
};

const calculateExperienceOfUniquePositions = (
  positionPeriods: Map<PositionId, PositionPeriod>,
): PositionExperience[] => {
  const positionExperiences: PositionExperience[] = [];

  positionPeriods.forEach((positionPeriod) => {
    positionExperiences.push({
      position: positionPeriod.position,
      experience: calculateDuration(intersectPeriods(positionPeriod.period)),
    });
  });

  return positionExperiences;
};

const calculatePositionsExperience = (
  activities: CvActivity[],
): PositionExperience[] => {
  const activitiesClone = deepClone(activities);
  const trimmedActivities = trimSubactivitiesPeriods(activitiesClone);
  const positionPeriods =
    groupSubactivityPeriodsByUniquePositions(trimmedActivities);
  return calculateExperienceOfUniquePositions(positionPeriods);
};

export default calculatePositionsExperience;
