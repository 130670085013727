import setValue, { SetValue } from '../../setValue';

import SubactivitiesResource from '../../../../tools/resources/types/business/subactivities/SubactivitiesResource';

import { LOAD_SUBACTIVITIES_RESOURCE_SET } from '../resourceActionTypes';

export type SetSubactivitiesResource = SetValue<
  typeof LOAD_SUBACTIVITIES_RESOURCE_SET,
  SubactivitiesResource
>;

const setSubactivitiesResource = (resource: SubactivitiesResource) =>
  setValue(LOAD_SUBACTIVITIES_RESOURCE_SET, resource);

export default setSubactivitiesResource;
